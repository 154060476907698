import ConfigManager from "@/config/ConfigManager.js";
import BzbsApi from "@/core/Http/BzbsApi.js";
import { map } from "rxjs/operators";
import CacheHelper from "@/helper/CacheHelper.js";

/**
 * @method apiGetPDPAVersion
 * @method apiGetPDPAContent
 */

var Cache = new CacheHelper(ConfigManager.getCacheType());
let path = window.location.host.includes("localhost")
    ? "http://localhost:8090"
    : process.env.VUE_APP_API_BASE_URL;
let path_stock = window.location.host.includes("localhost")
    ? "http://localhost:8099"
    : process.env.VUE_APP_API_BASE_URL_STOCK;
export default {
    /**
     * Return PDPA version
     */
    apiGetPDPAVersion: function() {
        var appId = ConfigManager.getAppId();
        var url = ConfigManager.getBlobUrl() + `/pdpaconsent/${appId}/version`;
        return BzbsApi.connectBzbsGetBlob(url).pipe(map(res => res.data));
    },

    /**
     * Return PDPA contents.
     * @param {String} contentType Terms, privacy, etc..
     * @param {Number} version PDPA content version
     * @param {Number} language PDPA language code.
     */
    apiGetPDPAContent: function(contentType, version, language) {
        const appId = ConfigManager.getAppId();
        const url =
            ConfigManager.getBlobUrl() +
            `/pdpaconsent/${appId}/${contentType}/${version}/${language}`;
        return BzbsApi.connectBzbsGetBlob(url).pipe(map(res => res.data));
    },

    /**
     * @param {string} token
     * @param {number|string} termandcondition
     * @param {number|string} dataprivacy
     */
    apiPostuserDetail: function(token, obj, appid, page) {
        var params = {
            profile_update: obj.profile_update,
            joindate: obj.joindate,
            birthday: obj.birthday,
            age: obj.age,
            gender: obj.gender,
            province: obj.province,
            fraud_status: obj.fraud_status,
            user_level: obj.user_level,
            total_earn: obj.total_earn,
            total_burn: obj.total_burn,
            point_on_hand: obj.point_on_hand,
            last_active: obj.last_active,
            last_redeem: obj.last_redeem,
            last_scan: obj.last_scan,
            registration_type: obj.registration_type,
            numberof_friend: obj.numberof_friend
        };
        var url =
            ConfigManager.getApiUrl() +
            "/main/userDetail/" +
            appid +
            "/" +
            page;
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiPostuserlistSearch: function(token, params, appid, page) {
        var url =
            ConfigManager.getApiUrl() +
            "/search/userlistSearch/" +
            appid +
            "?page=" +
            page;
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiPostuserExport: function(obj, appid) {
        var params = {
            profile_update: {},
            joindate: {},
            birthday: {},
            age: {},
            gender: {},
            province: {},
            fraud_status: {},
            user_level: {},
            total_earn: {},
            total_burn: {},
            point_on_hand: {},
            last_active: {},
            last_redeem: {},
            last_scan: {},
            registration_type: {},
            numberof_friend: {},
            columns: obj.columns,
            filename: obj.filename,
            description: obj.description,
            staffid: obj.staffid
        };

        var url = ConfigManager.getApiUrl() + "/main/userExport/" + appid;
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiPostuserExportLog: function(obj, appid) {
        var params = {
            startdate: obj.startdate,
            enddate: obj.enddate,
            clearlist: obj.clearlist,
            search_filename: obj.search_filename
        };

        if (obj.uid) params.uid = obj.uid;
        var url = ConfigManager.getApiUrl() + "/main/userExportLog/" + appid;
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiPostUserExportRefresh: function(obj, appid) {
        var params = {
            uid: obj.uid,
            status: obj.status,
            download: false
        };
        var url =
            ConfigManager.getApiUrl() + "/main/userExportLogRefresh/" + appid;
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiPostUserlistFilter: function(params, appid) {
        var url = ConfigManager.getApiUrl() + "/filter/userlistFilter/" + appid;
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiSellOrderTransaction: function(params) {
        var url = path + "/api/createordertransaction";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiGetCustomer: function() {
        const url = path + "/api/customer";
        return BzbsApi.connectBzbsGet(url);
    },
    apiGetCustomer: function() {
        const url = path + "/api/customeruserproduct";
        return BzbsApi.connectBzbsGet(url);
    },
    apiGetcheckinsertcustomercode: function(code) {
        const url = path + "/api/insertcustomercode/" + code;
        return BzbsApi.connectBzbsGet(url);
    },
    apiGetremovecustomercode: function(customerid, code) {
        const url = path + `/api/removecustomercode/${customerid}/${code}`;
        return BzbsApi.connectBzbsGet(url);
    },

    apiGetMakeSP: function() {
        const url = path + "/api/makesp";
        return BzbsApi.connectBzbsGet(url);
    },
    apiGetCustomercustomercode: function(id) {
        const url = path + "/api/customercustomercode/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apiGetCustomerProductbyCustomercode: function(id, ot) {
        const url =
            path + "/api/customerproductbycustomercode/" + id + "/" + ot;
        return BzbsApi.connectBzbsGet(url);
    },

    apiPostCreateCustomer: function(params) {
        var url = path + "/api/addCustomer";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicheckordertransaction: function(params) {
        var url = path + "/api/checkordertransaction";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apiPostCreateCustomerUserProduct: function(params) {
        var url = path + "/api/createcustomeruserproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiUpdateCustomer: function(params) {
        params.jsonip = Cache.getCache("jsonip");
        var url = path + "/api/updatecustomer";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiUpdateCustomerUserProduct: function(params) {
        params.jsonip = Cache.getCache("jsonip");
        var url = path + "/api/updatecustomeruserproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicountries: function() {
        const url = path + "/api/countries/";
        return BzbsApi.connectBzbsGet(url);
    },
    apicountriesphone: function() {
        let json = require("/src/plugins/phone.json");
        return Promise.resolve({ data: json });
    },

    apiprovince: function() {
        const url = path + "/api/province";
        return BzbsApi.connectBzbsGet(url);
    },
    apiborders: function(id) {
        const url = path + "/api/borders/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apidistricts: function(bordersid, districtsid) {
        const url =
            path + "/api/districts/" + bordersid + "/" + districtsid + "";
        return BzbsApi.connectBzbsGet(url);
    },
    apipostals: function(id) {
        const url = path + "/api/postals/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apisellorder: function(params) {
        const url = path + "/api/sellorder";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetnotimessage: function(params) {
        const url = path + "/api/getnotimessage";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apireadnotimessage: function(params) {
        const url = path + "/api/readnotimessage";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiproductsellorder: function(params) {
        const url = path + "/api/productsellorder";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apisallorderlist: function() {
        const url = path + "/api/sallorderlist/";
        return BzbsApi.connectBzbsGet(url);
    },
    apiprompostuserinfo: function() {
        const url = path + "/api/prompostuserinfo";
        return BzbsApi.connectBzbsGet(url);
    },
    apigetproductnotcreate: function() {
        const url = path + "/api/getproductnotcreate";
        return BzbsApi.connectBzbsGet(url);
    },

    apigetprompost: function(params) {
        const url = path + "/api/getprompost";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetprompostinter: function(params) {
        const url = path + "/api/getprompostinter";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiuploadimage: function(params) {
        const url = path + "/api/uploadfile";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiuploadtypefile: function(params) {
        const url = path + "/api/uploadtypefile";
        return BzbsApi.connectBzbsPostFile(url, null, params);
    },
    apigethistoryeasyslip: function(params) {
        const url = path + "/api/gethistoryeasyslip";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    updatestatusreport: function(params) {
        const url = path + "/api/updatestatusreport";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    updatestatusreportissystemandpayment: function(params) {
        const url = path + "/api/updatestatusreportissystemandpayment";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    updatestatusreportstock: function(params) {
        const url = path_stock + "/api/updatestatusreportstock";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    updatestatussupport: function(params) {
        const url = path + "/api/updatestatussupport";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    getsellorderdetail: function(params) {
        const url = path + "/api/getsellorderdetail";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    updateshippingsellorder: function(params) {
        const url = path + "/api/updateshippingsellorder";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiproduct: function(params) {
        const url = path + "/api/createproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproductstatus: function(params) {
        const url = path + "/api/updateproductstatus";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproductdeliverystatus: function(params) {
        const url = path + "/api/updateproductdeliverystatus";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apisentemailtracking: function(params) {
        const url = path + "/api/senttrackingemail";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiexportaddress: function(params) {
        const url = path + "/api/export_pdf_address";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproduct: function(params) {
        params.returnurl = window.location.href.replace(
            window.location.origin,
            ""
        );
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/updateproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdatestock: function(params) {
        const url = path_stock + "/api/updatestatusstock";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apireturnproductstock: function(params) {
        const url = path + "/api/returnproductstock";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproductstock: function(params) {
        params.returnurl = window.location.href.replace(
            window.location.origin,
            ""
        );
        params.jsonip = Cache.getCache("jsonip");
        const url = path_stock + "/api/updateproductstock";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproductcaseincrease: function(params) {
        const url = path + "/api/updateproductcaseincrease";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproductlogchenge: function(params) {
        const url = path + "/api/updateproductlogchenge";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    deleteproduct: function(params) {
        const url = path + "/api/deleteproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    deleteproductstock: function(params) {
        const url = path + "/api/deleteproductstock";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    //sms
    apisentsmsthai: function(params) {
        const url = path + "/api/sentsmsthai";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apisentsmsinter: function(params) {
        const url = path + "/api/sentsmsinter";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    //sms

    //contact
    apidelcontact: function(params) {
        const url = path + "/api/delcontact";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicreatecontact: function(params) {
        const url = path + "/api/createcontact";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdatecontact: function(params) {
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/updatecontact";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicontact: function(id) {
        const url = path + "/api/contact/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    //contact
    //delivery
    apicreatedelivery: function(params) {
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/updatedelivery";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicreatedeliveryremind: function(params) {
        const url = path + "/api/updatedeliveryremind";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apicancelsp: function(params) {
        const url = path + "/api/apicancelsp";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetdeliveryremind: function(cusid, pk) {
        debugger;
        const url = path + "/api/deliveryremind/" + cusid + "/" + pk;
        return BzbsApi.connectBzbsGet(url);
    },

    apigetadditionaldeliveryorder: function(status) {
        const url = path + "/api/getadditionaldeliveryorder/" + status;
        return BzbsApi.connectBzbsGet(url);
    },

    apiupdateadditionalshipping: function(params) {
        const url = path + "/api/updateadditionalshipping";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateadditionalshipping_update_edit: function(params) {
        const url = path + "/api/updateadditionalshipping_update_edit";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiproductdelivery: function(params) {
        const url = path + "/api/createdeliveryaddress";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiproductmastershortness: function(params) {
        const url = path + "/api/shortness";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apideleteproductdeliveryaddress: function(params) {
        const url = path + "/api/deleteproductdeliveryaddress";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apiupdateproductdelivery: function(params) {
        const url = path + "/api/updatedeliveryaddress";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproductdeliverythailandposttracking: function(params) {
        const url = path + "/api/updatedelivery_thailandposttracking";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    updatepaymentchecking: function(params) {
        params.returnurl = window.location.href.replace(
            window.location.origin,
            ""
        );
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/updatepaymentchecking";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    updatepaymentcheckingaddtional: function(params) {
        params.returnurl = window.location.href.replace(
            window.location.origin,
            ""
        );
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/updatepaymentcheckingaddtional";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    updatesystemchecktabactive: function(params) {
        const url = path + "/api/updatesystemchecktabactive";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    getchecking: function(params) {
        const url =
            path +
            "/api/getchecking/" +
            params.ProductSellOrder +
            "/" +
            params.CustomerOrder;
        return BzbsApi.connectBzbsGet(url);
    },
    getcheckingaddtional: function(params) {
        const url =
            path +
            "/api/getcheckingaddtional/" +
            params.AdditionalTage +
            "/" +
            params.CustomerOrder;
        return BzbsApi.connectBzbsGet(url);
    },
    getsystemchecktabactive: function(params) {
        const url =
            path +
            "/api/getsystemchecktabactive/" +
            params.ProductSellOrder +
            "/" +
            params.CustomerOrder +
            "/" +
            params.ProductKey;
        return BzbsApi.connectBzbsGet(url);
    },
    getusercommission: function(params) {
        const url = path + "/api/getusercommission/";
        return BzbsApi.connectBzbsGet(url);
    },

    getcareer: function() {
        const url = path + "/api/getcareer";
        return BzbsApi.connectBzbsGet(url);
    },

    getproductlogchangehistory: function(productsellorder) {
        const url =
            path + "/api/getproductlogchangehistory/" + productsellorder;
        return BzbsApi.connectBzbsGet(url);
    },
    getproductstock: function(productsellorder) {
        const url = path_stock + "/api/getproductstock/" + productsellorder;
        return BzbsApi.connectBzbsGet(url);
    },
    getproductstocklistall: function(productsellorder) {
        const url = path_stock + "/api/getproductstocklistall";
        return BzbsApi.connectBzbsGet(url);
    },
    getcheckrefnumber: function(ref) {
        const url = path + "/api/checkrefnumber/" + ref;
        return BzbsApi.connectBzbsGet(url);
    },

    apipayment: function(params) {
        const url = path + "/api/createpayment";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apideleteupdatepayment: function(params) {
        const url = path + "/api/deleteupdatepayment";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apiupdatepayment: function(params) {
        params.returnurl = window.location.href.replace(
            window.location.origin,
            ""
        );
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/updatepayment";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdatepaymentadditional: function(params) {
        const url = path + "/api/updatepaymentadditional";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apiupdatepaymentadditional_update_edit: function(params) {
        const url = path + "/api/updatepaymentadditional_update_edit";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicustomerproduct: function(params) {
        var token = Cache.getCache("auth")?.accessToken;
        const url = path + "/api/customerproduct";
        return BzbsApi.connectBzbsGet(url, token, params);
    },
    customerproductpagination: function(params) {
        var token = Cache.getCache("auth")?.accessToken;
        const url =
            path +
            `/api/customerproductpagination/${params.page}/${params.limit}`;
        return BzbsApi.connectBzbsGet(url, token, params);
    },
    search_criteria_customerproduct: function(params) {
        const url = path + "/api/search_criteria_customerproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apiusestockdetail: function(stockuuid) {
        const url = path_stock + "/api/usestockdetail/" + stockuuid;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetusercommissionlog: function(productsellorder, productkey) {
        const url =
            path +
            "/api/getusercommissionlog/" +
            productsellorder +
            "/" +
            productkey;
        return BzbsApi.connectBzbsGet(url);
    },

    apilineuser: function(params) {
        const url = path + "/api/getlineuser";
        return BzbsApi.connectBzbsGet(url, null, params);
    },
    apigetproductbyid: function(id) {
        const url = path + "/api/product/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetproductbyiddelivery: function(id) {
        const url = path + "/api/productdelivery/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetcheckhascustomer: function(id) {
        const url = path + "/api/checkhascustomer/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetproducstocktbyid: function(id) {
        const url = path + "/api/productstock/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetproductstockdetail: function(id) {
        const url = path_stock + "/api/productstockdetail/" + id;
        return BzbsApi.connectBzbsGet(url);
    },

    apigetproductsyanbycustomer: function(id, so) {
        const url = path + "/api/productsyanbycustomer/" + id + "/" + so;
        return BzbsApi.connectBzbsGet(url);
    },

    apigetproductlist: function(id) {
        const url = path + "/api/productlist/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    getcreatedelivery: function(id) {
        const url = path + "/api/getcreatedelivery/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    getcreatedeliverybycustomerid: function(id) {
        const url = path + "/api/getcreatedeliverybycustomerid/" + id;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetproductdeliveryaddress: function(
        customercode,
        productsellorder,
        type
    ) {
        const url =
            path +
            "/api/productdeliveryaddress/" +
            customercode +
            "/" +
            productsellorder +
            "/" +
            type;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetarraytran: function(ordertransactionfrom) {
        const url = path + "/api/getarraytran/" + ordertransactionfrom;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetpaymentbyso: function(productsellorder) {
        const url = path + "/api/getpaymentbyso/" + productsellorder;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetcalpricereportchangesize: function(ordertransaction) {
        const url =
            path + "/api/apigetcalpricereportchangesize/" + ordertransaction;
        return BzbsApi.connectBzbsGet(url);
    },

    apigetpaymentdetail: function(id, paymenttype, so) {
        const url = path + "/api/payment/" + id + "/" + paymenttype + "/" + so;
        return BzbsApi.connectBzbsGet(url);
    },

    apigetpaymentdetailadditional: function(id, paymenttype, so) {
        const url =
            path +
            "/api/paymentdetailadditional/" +
            id +
            "/" +
            paymenttype +
            "/" +
            so;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetpaymentdetailadditionalbysp: function(id, paymenttype, so) {
        const url =
            path +
            "/api/paymentdetailadditionalbysp/" +
            id +
            "/" +
            paymenttype +
            "/" +
            so;
        return BzbsApi.connectBzbsGet(url);
    },

    apicreatesalepage: function(params) {
        const url = path + "/api/createsalepage";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicreatesubdomain: function(params) {
        const url = path + "/api/createsubdomain";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apisetmainsalepage: function(params) {
        const url = path + "/api/setmainsalepage";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apisetdeletesubdomain: function(params) {
        const url = path + "/api/removesubdomain";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apisetdeletesalepage: function(params) {
        const url = path + "/api/setdeletesalepage";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apilogin: function(params) {
        const url = path + "/api/login";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiposts: function(params) {
        const url = path + "/api/auth";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apisentemail: function(params) {
        const url = path + "/api/getauthemail";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicheckemail: function(params) {
        const url = path + "/api/checkauthemail";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiinterweight: function(countrycode, weight) {
        const url = path + "/api/inter_weight/" + countrycode + "/" + weight;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetpathinter: function(track) {
        const url = path + "/api/apigetpathinter/" + track;
        return BzbsApi.connectBzbsGet(url);
    },

    apigetip: function() {
        const url = "https://api.ipify.org/?format=json";
        return BzbsApi.connectBzbsGet(url);
    },
    apigetservicethailandpost: function() {
        const url = path + "/api/getservicethailandpost";
        return BzbsApi.connectBzbsGet(url);
    },
    apigeths: function() {
        const url = path + "/api/hs";
        return BzbsApi.connectBzbsGet(url);
    },
    apigetcreatedinter: function() {
        const url = path + "/api/getcreatedinter";
        return BzbsApi.connectBzbsGet(url);
    },
    getcreatedprepost: function() {
        const url = path + "/api/getcreatedprepost";
        return BzbsApi.connectBzbsGet(url);
    },
    getyancomming: function(startdate, enddate) {
        const url = path + "/api/getyancomming/" + startdate + "/" + enddate;
        return BzbsApi.connectBzbsGet(url);
    },
    getyanstock: function(startdate, enddate, typeshow) {
        const url =
            path_stock +
            "/api/getyanstock/" +
            startdate +
            "/" +
            enddate +
            "/" +
            typeshow;
        return BzbsApi.connectBzbsGet(url);
    },
    getyanvip: function(startdate, enddate) {
        const url = path + "/api/getyanvip/" + startdate + "/" + enddate;
        return BzbsApi.connectBzbsGet(url);
    },

    getacceptsystemyandate: function(startdate, enddate) {
        const url =
            path + "/api/getacceptsystemyandate/" + startdate + "/" + enddate;
        return BzbsApi.connectBzbsGet(url);
    },
    getreportcommission: function(
        startdate,
        enddate,
        isshowvip,
        isshowchangesize,
        isshownaw
    ) {
        const url =
            path +
            "/api/getreportcommission/" +
            startdate +
            "/" +
            enddate +
            "/" +
            isshowvip +
            "/" +
            isshowchangesize +
            "/" +
            isshownaw;
        return BzbsApi.connectBzbsGet(url);
    },
    getreportnotpay: function(startdate, enddate, isshowvip, isshowchangesize) {
        const url =
            path +
            "/api/getreportnotpay/" +
            startdate +
            "/" +
            enddate +
            "/" +
            isshowvip +
            "/" +
            isshowchangesize;
        return BzbsApi.connectBzbsGet(url);
    },
    getreportproduct: function(
        startdate,
        enddate,
        startdate_transfer_money,
        enddate_transfer_money,
        isshowyannormal,
        isshowvip,
        isshowchangesize,
        productnamesearch
    ) {
        const url =
            path +
            "/api/getreportproduct/" +
            startdate +
            "/" +
            enddate +
            "/" +
            startdate_transfer_money +
            "/" +
            enddate_transfer_money +
            "/" +
            isshowyannormal +
            "/" +
            isshowvip +
            "/" +
            isshowchangesize +
            "/" +
            productnamesearch;
        return BzbsApi.connectBzbsGet(url);
    },
    getacceptsystemyandatechecked: function(startdate, enddate) {
        const url =
            path +
            "/api/getacceptsystemyandatechecked/" +
            startdate +
            "/" +
            enddate;
        return BzbsApi.connectBzbsGet(url);
    },

    getyanchangesize: function(startdate, enddate) {
        const url = path + "/api/getyanchangesize/" + startdate + "/" + enddate;
        return BzbsApi.connectBzbsGet(url);
    },
    getcontactpeak: function(startdate, enddate) {
        const url = path + "/api/getcontactpeak/" + startdate + "/" + enddate;
        return BzbsApi.connectBzbsGet(url);
    },
    getlistaccount: function() {
        const url = path + "/api/getlistaccount/";
        return BzbsApi.connectBzbsGet(url);
    },

    getexcelcontact: function(startdate, enddate) {
        const url = path + "/api/getexcelcontact/" + startdate + "/" + enddate;
        return BzbsApi.connectBzbsGet(url);
    },
    getexcelcontact_obj: function(params) {
        const url = path + "/api/getexcelcontact_obj";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    getcreatedprepostbytrack: function(track) {
        const url = path + "/api/getcreatedprepostbytrack/" + track;
        return BzbsApi.connectBzbsGet(url);
    },
    getaddressbysp: function(cuscode, sp) {
        const url = path + "/api/getaddressbysp/" + cuscode + "/" + sp;
        return BzbsApi.connectBzbsGet(url);
    },

    gettrackbycuscode: function(cuscode) {
        const url = path + "/api/gettrackbycuscode/" + cuscode;
        return BzbsApi.connectBzbsGet(url);
    },

    getcreatedprepostbytrackinter: function(track) {
        const url = path + "/api/getcreatedprepostbytrackinter/" + track;
        return BzbsApi.connectBzbsGet(url);
    },
    getconsumerhistory: function(track, cuscode, so, pk) {
        const url =
            path +
            "/api/getconsumerhistory/" +
            track +
            "/" +
            cuscode +
            "/" +
            so +
            "/" +
            pk;
        return BzbsApi.connectBzbsGet(url);
    },
    getconsumer: function(track) {
        const url = path + "/api/getconsumer/" + track;
        return BzbsApi.connectBzbsGet(url);
    },
    getimagegreen: function(ProductSellOrder) {
        const url = path + "/api/getimagegreen/" + ProductSellOrder;
        return BzbsApi.connectBzbsGet(url);
    },
    apiscantrackingproduct: function(params) {
        const url = path + "/api/scantrackingproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdatelineprofiletoproduct: function(params) {
        const url = path + "/api/updatelineprofiletoproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicheckowneraccount: function(params) {
        debugger;
        const url = path + "/api/checkowneraccount";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apiregister: function(params) {
        const url = path + "/api/register";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    checktrack: function(params) {
        const url = path + "/api/checktrack";
        return BzbsApi.connectBzbsPost(url, null, params);
    },

    apiscantracking: function(params) {
        const url = path + "/api/scantracking";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdateproductbirthday: function(params) {
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/updateproductbirthday";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apidelete_consumer_productbirthday: function(params) {
        params.jsonip = Cache.getCache("jsonip");
        const url = path + "/api/delete_consumer_productbirthday";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetproductbirthday: function(id, productkey, productsellorder) {
        debugger;
        const url =
            path +
            "/api/productbirthday/" +
            id +
            "/" +
            productkey +
            "/" +
            productsellorder;
        return BzbsApi.connectBzbsGet(url);
    },
    apideleteproductbirthday: function(params) {
        const url = path + "/api/deleteproductbirthday";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiadduserthailandpost: function(params) {
        const url = path + "/api/adduserthailandpost";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apilogindpostinter: function(params) {
        const url = "https://dpostinter.thailandpost.com/api/token";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetuserthailandpost: function() {
        const url = path + "/api/getuserthailandpost";
        return BzbsApi.connectBzbsGet(url);
    },
    apigetlinelog: function(params) {
        const url = path + "/api/getlinelog";
        return BzbsApi.connectBzbsGet(url, null, params);
    },
    apigetshoppingcart: function(params) {
        const url = path + "/api/getshoppingcart";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    getshoppingcartcalendar: function(params) {
        const url = path + "/api/getshoppingcartcalendar";
        return BzbsApi.connectBzbsGet(url, null, params);
    },

    apiupdatedefaultproductdeliveryaddresstbycustomerorder: function(params) {
        const url =
            path + "/api/updatedefaultproductdeliveryaddresstbycustomerorder";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetdefaultproductdeliveryaddress: function(
        customercode,
        productsellorder,
        additionaltage
    ) {
        const url =
            path +
            "/api/getdefaultproductdeliveryaddress/" +
            customercode +
            "/" +
            productsellorder +
            "/" +
            additionaltage;
        return BzbsApi.connectBzbsGet(url);
    },
    gencustomercode: function() {
        var url = path + "/api/gencustomercode";
        return BzbsApi.connectBzbsPost(url, null, null);
    },
    apireportgreen: function(params) {
        const url = path + "/api/getreportgreen";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportstocknaw: function(params) {
        const url = path + "/api/getreportstocknaw";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportred: function(params) {
        const url = path + "/api/getreportred";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportgetyan: function(params) {
        const url = path + "/api/getreportgetyan";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportproductpdf: function(params) {
        const url = path + "/api/getreportproductpdf";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apigetexcelproduct: function(params) {
        const url = path + "/api/getexcelproduct";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportgetyanstock: function(params) {
        const url = path_stock + "/api/getreportgetyanstock";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportgetyanvip: function(params) {
        const url = path + "/api/getreportgetyanvip";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    getreportpdfnotpay: function(params) {
        const url = path + "/api/getreportpdfnotpay";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    getreportpdfcommission: function(params) {
        const url = path + "/api/getreportpdfcommission";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportgetyanchangesize: function(params) {
        const url = path + "/api/getreportgetyanchangesize";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportgetyanchangesizecommission: function(params) {
        const url = path + "/api/getreportgetyanchangesizecommission";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },

    apireportgetexcelshopping: function(params) {
        const url = path + "/api/getexcelshopping";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apireportgetexcelshopping_fill_in: function(params) {
        const url = path + "/api/getexcelshopping_fill_in";
        return BzbsApi.connectBzbsPostBlob(url, null, params);
    },
    apigetmastertype: function() {
        const url = path + "/api/getmastertype";
        return BzbsApi.connectBzbsGet(url);
    },
    apigetcategorymastertype: function(type) {
        const url = path + "/api/getcategorymastertype/" + type;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetmasterproduct: function(type) {
        const url = path + "/api/getmasterproduct/" + type;
        return BzbsApi.connectBzbsGet(url);
    },
    apigetmasterproductbyname: function(productcode) {
        const url = path + "/api/getmasterproductbyname/" + productcode;
        return BzbsApi.connectBzbsGet(url);
    },
    apiget_log_masterproductbyname: function(productcode) {
        const url = path + "/api/get_log_masterproductbyname/" + productcode;
        return BzbsApi.connectBzbsGet(url);
    },

    apiupdatemastertype: function(params) {
        const url = path + "/api/updatemastertype";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiupdatemasterproduct: function(params) {
        const url = path + "/api/updatemasterproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetoptionscategory1: function(params) {
        const url = path + "/api/get_master_category_1";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetoptionscategory2: function(params) {
        const url = path + "/api/get_master_category_2";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetoptionscategory3: function(params) {
        const url = path + "/api/get_master_category_3";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apigetoptionscategory4: function(params) {
        const url = path + "/api/get_master_category_4";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apideletemasterproduct: function(params) {
        const url = path + "/api/deletemasterproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apicategoryproduct: function(params) {
        const url = path + "/api/categoryproduct";
        return BzbsApi.connectBzbsPost(url, null, params);
    },
    apiproductreport: function(params) {
        const url = path + "/api/productreport";
        return BzbsApi.connectBzbsPost(url, null, params);
    }
};
