<template>
    <div class="sticky top-0 z-10 topbar-wrapper">
        <div
            class="flex items-center justify-between bg-white border-b top-bar scroll-touch"
        >
            <div class="pl-4 left-section">
                <v-breadcrumb
                    :breadcrumb="breadcrumb"
                    :propsMode="breadcrumbPropsMode"
                >
                    <span
                        class="text-lg font-bold text-black"
                        v-text="defaultTitle"
                    ></span>
                </v-breadcrumb>
            </div>
            <div v-if="false" @click="updatebugshowModal">
                <bug-outlined
                    style="font-size: 20px;padding:10px"
                />อัพเดท/ปรับแก้ไข
                <a-modal
                    v-model:visible="updatebugvisible"
                    title="ข้อมูล"
                    @ok="updatebughandleOk"
                    width="1000px"
                >
                    <a-tree
                        v-model:expandedKeys="expandedKeys"
                        v-model:selectedKeys="selectedKeys"
                        show-line
                        :tree-data="treeData"
                    >
                        <template #switcherIcon="{ switcherCls }"
                            ><down-outlined :class="switcherCls"
                        /></template>
                    </a-tree>
                </a-modal>
            </div>
            <div class="flex items-center pr-4 right-section">
                <div class="mr-4">
                    <a-image
                        :width="30"
                        src="https://namahayan.blob.core.windows.net/assets/linebot.png"
                    />
                    <div style="font-size: 7px;margin-top: -6px;">
                        LINE BOT
                    </div>
                </div>
                <div class="mr-8">
                    <div @click="gotostock()">
                        <api-outlined style="font-size: 25px" />สร้างรายการสต๊อก
                    </div>

                    <!-- <div class="relative cursor-pointer icon" @click="toggleNotification()">
                        <div class="pt-4 pr-3">
                            <img src="@/assets/icons/button/btn-notification.svg" alt />
                        </div>

                        <div
                            class="absolute right-0 px-1 text-xs leading-none text-white rounded-lg top-2 bg-red-1 py-2px"
                        >{{ notificationCount }}</div>
                    </div> -->
                </div>
                <!-- <button @click="testclick()">SENT</button> -->
                <div
                    class="m-4 flex items-center cursor-pointer user-menu pr-4"
                    @click="togglenotiaccount()"
                >
                    <div class="text-right"></div>
                    <div>
                        <!-- <a-badge :count="formStateStore.noti.count" title="Custom hover text">
                            <notification-outlined />
                            <v-avatar class="pl-2" :text="'JOB'" bg-color="bg-orange-2" color="text-orange-1">
                            </v-avatar>
                        </a-badge> -->

                        <a-badge :count="formStateStore.noti.count">
                            <notification-outlined style="font-size: 25px" />
                        </a-badge>
                    </div>
                    <NotiAccount
                        v-if="updatenoti"
                        @updatedata="updatedata"
                    ></NotiAccount>
                </div>
                <div
                    class="flex items-center cursor-pointer user-menu"
                    @click="toggleUserAccountPanel()"
                >
                    <div class="text-right">
                        <div class="text-sm text-gray-1">
                            {{ user?.displayName }}
                        </div>
                        <div class="text-xs text-gray-1-light">
                            {{ user?.fullName }} | {{ user?.role }}
                        </div>
                    </div>
                    <div>
                        <v-avatar
                            class="pl-2"
                            :text="user?.shortedName"
                            bg-color="bg-orange-2"
                            color="text-orange-1"
                        >
                        </v-avatar>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="hasSlot('toolbar')">
            <div class="pl-4 pr-4 bg-white border-t border-b">
                <slot name="toolbar"></slot>
            </div>
        </template>
    </div>
</template>
<script>
import {
    NotificationOutlined,
    CheckCircleOutlined,
    CloseOutlined,
    ApiOutlined,
    BugOutlined
} from "@ant-design/icons-vue";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.vue";
import Avatar from "@/components/Avatar/Avatar.vue";
import { account_profile } from "@/store/store";
import Storedata from "@/store/Storedata.js";
import CacheHelper from "@/helper/CacheHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
import NotiAccount from "@/module/Layout/LeftMenu/component/NotiAccount.vue";
import { formStateStore } from "@/store/storebackoffice";
var Cache = new CacheHelper(ConfigManager.getCacheType());
import { io } from "socket.io-client";
import { notification } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { h } from "vue";

// let path = window.location.host.includes("localhost")
//     ? "http://localhost:8090"
//     : process.env.VUE_APP_API_BASE_URL;

var url_customer = window.location.href;
var is_show_noti = true;

// let socket;
// let hasConnected = false; // ตัวแปรสำหรับตรวจสอบสถานะการเชื่อมต่อ
// try {
//     socket = io.connect(path, {
//         reconnection: true, // เปิดใช้งานการเชื่อมต่อใหม่
//         reconnectionAttempts: 5, // จำกัดจำนวนครั้งที่พยายามเชื่อมต่อใหม่
//         reconnectionDelay: 2000, // หน่วงเวลา 2 วินาทีก่อนพยายามครั้งถัดไป
//         reconnectionDelayMax: 5000, // หน่วงเวลาไม่เกิน 5 วินาที
//         timeout: 10000 // Timeout ของการเชื่อมต่อ (10 วินาที)
//     });
// } catch (error) {
//     // Handle the error
//     console.error("Error occurred while connecting to socket:", error);
// }

// //ตรวจสอบ หน้าลูกค้าไม่ต้องแสดง noti
// if (url_customer.includes("consumermenu")) {
//     is_show_noti = false;
//     // Handle consumermenu URL
//     console.log("Handling consumermenu URL");
// } else if (url_customer.includes("consumerdeliverydetail")) {
//     is_show_noti = false;
//     // Handle consumerdeliverydetail URL
//     console.log("Handling consumerdeliverydetail URL");
// } else if (url_customer.includes("consumerhistory")) {
//     is_show_noti = false;
//     // Handle consumerhistory URL
//     console.log("Handling consumerhistory URL");
// } else if (url_customer.includes("consumeryandetail")) {
//     is_show_noti = false;
//     // Handle consumeryandetail URL
//     console.log("Handling consumeryandetail URL");
// } else if (url_customer.includes("consumerbirthday")) {
//     is_show_noti = false;
//     // Handle consumeryandetail URL
//     console.log("Handling consumeryandetail URL");
// } else if (url_customer.includes("consumeryandetailbrithDay")) {
//     is_show_noti = false;
//     // Handle consumeryandetail URL
//     console.log("Handling consumeryandetail URL");
// } else if (url_customer.includes("consumerlogin")) {
//     is_show_noti = false;
//     // Handle consumeryandetail URL
//     console.log("Handling consumeryandetail URL");
// } else {
//     is_show_noti = true;
//     // Handle other URLs or invalid URLs
//     console.log("URL not recognized or supported");
// }

// if (is_show_noti) {
//     socket.on("sentnoti", message => {
//         console.log(Storedata.methods.checkgrantccountonly());
//         if (
//             message.role == "account" &&
//             !Storedata.methods.checkgrantccountonly()
//         ) {
//             var param = {
//                 Role: message.role,
//                 Type: message.type
//             };

//             BzbsUser.apigetnotimessage(param)
//                 .then(res => {
//                     formStateStore.noti.listnoti = res.data;
//                 })

//                 .catch(error => {
//                     console.log(error);
//                 });

//             formStateStore.noti.detail.push(message);
//             formStateStore.noti.count = formStateStore.noti.count + 1;

//             var placement = "bottomRight";
//             notification.open({
//                 message: `${message.title}`,
//                 description: `${message.desc}`,
//                 placement,
//                 duration: 0,
//                 onClick: e => {
//                     window.location = message.url;
//                 }
//             });
//         }
//         if (
//             (message.role == "admin" && Storedata.methods.isadmin()) ||
//             message.role == "support" ||
//             message.role == "officersupport"
//         ) {
//             Storedata.methods.callselllist();
//             var param = {
//                 Role: message.role,
//                 Type: message.type
//             };

//             BzbsUser.apigetnotimessage(param)
//                 .then(res => {
//                     formStateStore.noti.listnoti = res.data;
//                 })

//                 .catch(error => {
//                     console.log(error);
//                 });

//             formStateStore.noti.detail.push(message);
//             formStateStore.noti.count = formStateStore.noti.count + 1;
//             var txtstatus = "";
//             var placement = "bottomRight";
//             var icon = null;
//             if (message.status == "checked") txtstatus = "ผ่าน";
//             icon = CheckCircleOutlined;
//             if (message.status == "reject") txtstatus = "ไม่ผ่าน";
//             icon = CloseOutlined;
//             notification.open({
//                 message: `${message.title} *${txtstatus}`,
//                 description: `${message.desc}`,
//                 placement,
//                 duration: 0,
//                 onClick: e => {
//                     window.location = message.url;
//                 },
//                 icon: () =>
//                     h(icon, {
//                         style: "color: #108ee9"
//                     })
//             });
//         }
//         if (message.role == "officer" || message.role == "officersupport") {
//             Storedata.methods.callselllist();
//             var param = {
//                 Role: message.role,
//                 Type: message.type
//             };

//             BzbsUser.apigetnotimessage(param)
//                 .then(res => {
//                     formStateStore.noti.listnoti = res.data;
//                 })

//                 .catch(error => {
//                     console.log(error);
//                 });

//             formStateStore.noti.detail.push(message);
//             formStateStore.noti.count = formStateStore.noti.count + 1;
//             var txtstatus = "";
//             var placement = "bottomRight";
//             var icon = null;
//             if (message.status == "checked") txtstatus = "ผ่าน";
//             icon = CheckCircleOutlined;
//             if (message.status == "reject") txtstatus = "ไม่ผ่าน";
//             icon = CloseOutlined;
//             notification.open({
//                 message: `${message.title} *${txtstatus}`,
//                 description: `${message.desc}`,
//                 placement,
//                 duration: 0,
//                 onClick: e => {
//                     window.location = message.url;
//                 },
//                 icon: () =>
//                     h(icon, {
//                         style: "color: #108ee9"
//                     })
//             });
//         }
//     });
// }

// Check if the current URL should disable notifications
const urlCustomer = window.location.href;
const pathsToDisableNoti = [
    "consumermenu",
    "consumerdeliverydetail",
    "consumerhistory",
    "consumeryandetail",
    "consumerbirthday",
    "consumeryandetailbrithDay",
    "consumerlogin"
];

const isShowNoti = !pathsToDisableNoti.some(path => urlCustomer.includes(path));

// Define the socket connection
const path = window.location.host.includes("localhost")
    ? "http://localhost:8090"
    : process.env.VUE_APP_API_BASE_URL;

let socket;
let hasConnected = false;

function connectSocket() { 
    if (!hasConnected) {
        try {
            socket = io.connect(path, {
                reconnection: true,
                reconnectionAttempts: 2,
                reconnectionDelay: 2000,
                reconnectionDelayMax: 5000,
                timeout: 10000
            });

            hasConnected = true;

            // Handle connection events
            socket.on("connect", () => {
                console.log("Socket connected: ", socket.id);
            });

            socket.on("sentnoti", handleNotification);

            socket.on("connect_error", error => {
                console.error("Connection error: ", error);
            });

            socket.on("disconnect", reason => {
                console.log("Socket disconnected: ", reason);
            });
            socket.on("server_shutdown", data => {
                console.warn(data.message); // แจ้งเตือนผู้ใช้
                socket.disconnect(); // ตัดการเชื่อมต่อ
            });
        } catch (error) {
            console.error("Error while connecting to socket: ", error);
        }
    }
}

function handleNotification(message) {
    console.log(Storedata.methods.checkgrantccountonly());

    const addNotification = (
        message,
        placement = "bottomRight",
        icon = null
    ) => {
        notification.open({
            message: `${message.title}`,
            description: `${message.desc}`,
            placement,
            duration: 0,
            onClick: () => (window.location = message.url),
            icon: icon ? () => h(icon, { style: "color: #108ee9" }) : null
        });

        formStateStore.noti.detail.push(message);
        formStateStore.noti.count++;
    };

    if (
        message.role === "account" &&
        !Storedata.methods.checkgrantccountonly()
    ) {
        const param = { Role: message.role, Type: message.type };

        BzbsUser.apigetnotimessage(param)
            .then(res => {
                formStateStore.noti.listnoti = res.data;
            })
            .catch(error => console.error(error));

        addNotification(message);
    }

    if (
        ["admin", "support", "officersupport"].includes(message.role) &&
        Storedata.methods.isadmin()
    ) {
        Storedata.methods.callselllist();
        const param = { Role: message.role, Type: message.type };

        BzbsUser.apigetnotimessage(param)
            .then(res => {
                formStateStore.noti.listnoti = res.data;
            })
            .catch(error => console.error(error));

        const icon =
            message.status === "checked" ? CheckCircleOutlined : CloseOutlined;
        const txtstatus = message.status === "checked" ? "ผ่าน" : "ไม่ผ่าน";

        addNotification(
            { ...message, title: `${message.title} *${txtstatus}` },
            "bottomRight",
            icon
        );
    }

    if (["officer", "officersupport"].includes(message.role)) {
        Storedata.methods.callselllist();
        const param = { Role: message.role, Type: message.type };

        BzbsUser.apigetnotimessage(param)
            .then(res => {
                formStateStore.noti.listnoti = res.data;
            })
            .catch(error => console.error(error));

        const icon =
            message.status === "checked" ? CheckCircleOutlined : CloseOutlined;
        const txtstatus = message.status === "checked" ? "ผ่าน" : "ไม่ผ่าน";

        addNotification(
            { ...message, title: `${message.title} *${txtstatus}` },
            "bottomRight",
            icon
        );
    }
}

// Initialize the connection only if notifications are enabled
// if (isShowNoti) {
//     connectSocket();
// }

export default {
    name: "TopBar",
    inject: ["AccountStore", "TopBarStore"],
    props: {
        breadcrumb: {
            type: Array,
            default: () => []
        },
        breadcrumbPropsMode: {
            type: Boolean,
            default: true
        }
    },
    components: {
        VBreadcrumb: Breadcrumb,
        VAvatar: Avatar,
        NotiAccount,
        NotificationOutlined,
        ApiOutlined,
        BugOutlined
    },
    data: function() {
        return {
            updatenoti: 0,
            noti: 0,
            formStateStore,
            updatebugvisible: false,
            expandedKeys: ["0-0-0"],
            selectedKeys: [],
            treeData: [
                {
                    title: `-หน้าสร้างรายการขาย`,
                    key: "0-0",
                    children: [
                        {
                            title:
                                "แก้ไขเรื่องกรอก LXXXXX ทันทีแล้วมีข้อมูล รายละเอียดลูกค้าแล้วบันทึกข้อมูลหาย",
                            key: "0-0-0"
                        }
                    ]
                },
                {
                    title: "-Report รายงานสินค้า",
                    key: "0-0",
                    children: [
                        {
                            title: "-เพิ่ม filter วันที่โอน",
                            key: "0-0-0"
                        },
                        {
                            title: "-เพิ่มรายละเอียดจำนวนเงิน",
                            key: "0-0-2"
                        },
                        {
                            title: "-เพิ่มแสดง ชื่อบัญชีในรายงาน",
                            key: "0-0-3"
                        }
                    ]
                },
                {
                    title: "-ระบบแจ้งเตือน",
                    key: "0-0",
                    children: [
                        {
                            title: "-เพิ่มปุ่ม กดสถานะอ่านแล้ว ทั้งหมด",
                            key: "0-0-0"
                        },
                        {
                            title:
                                "-เพิ่มการอัพเดทการเงินเมื่อมีการตรวจสอบ ให้อัพเดท noti สถานะ อ่านแล้ว",
                            key: "0-0-1"
                        },
                        {
                            title:
                                "-เพิ่มการแสดง noti ให้ user ประเภท officersupport ให้มีสิทธิมองเห็น noti สีม่วง(จัดส่ง)",
                            key: "0-0-2"
                        }
                    ]
                },
                {
                    title: "-รายงาน ใบขาว",
                    key: "0-0",
                    children: [
                        {
                            title: "-เพิ่ม แสดงวันที่โอน",
                            key: "0-0-0"
                        }
                    ]
                },
                {
                    title: "-อัพเดทข้อมูลบัญชี",
                    key: "0-0",
                    children: [
                        {
                            title: "-อัพเดท SCB แผ่นดวง >> กระแสรายวัน",
                            key: "0-0-0"
                        }
                    ]
                },
                {
                    title: "-ฟีเจอร์ตรวจสลิปเมื่ออัพโหลดไฟล์",
                    key: "0-0",
                    children: [
                        {
                            title:
                                "-เพิ่มฟีเจอร์ตรวจ Check สลิปเมื่ออัพโหลดไฟล์",
                            key: "0-0-0"
                        }
                    ]
                },
                {
                    title: "-เพิ่มฟีเจอร์อัพโหลดรูปสินค้า",
                    key: "0-0",
                    children: [
                        {
                            title:
                                "-อัพโหลดรูปได้ที่ ข้อมูลสินค้า >> เมนูสินค้า​ >> แก้ไข >> เพิ่มรูป",
                            key: "0-0-0"
                        }
                    ]
                },
                {
                    title: "-ระบบสร้างจัดส่ง",
                    key: "0-0",
                    children: [
                        {
                            title:
                                "-รายการ so สามารถสร้าง SP ใหม่ได้ มีการบันทึก recode ประวัติย้อนหลัง /แก้ไขเรื่อง อัพเดท Tracking/SP ทับข้อมูลเดิม",
                            key: "0-0-0"
                        },
                        {
                            title:
                                "-แจ้ง disable ตอนเลือก >> สร้างรายการค่าส่งเพิ่ม >> รายการที่สร้างเคยสร้าง sp แล้ว >> ตาราง (sell 1.1.1)",
                            key: "0-0-0"
                        }
                    ]
                },
                {
                    title: "-ระบบ stock ",
                    key: "0-0",
                    children: [
                        {
                            title:
                                "-เพิ่ม Link ย้อนกลับ แสดงว่ารายการไหนใช้ Stock ",
                            key: "0-0-0"
                        },
                        {
                            title: "-ตัด colum สีแดง",
                            key: "0-0-1"
                        },
                        {
                            title:
                                "-เพิ่มการอัพเดท เมื่อมีการตรวจสอบ ให้อัพเดท noti สถานะ อ่านแล้ว",
                            key: "0-0-1"
                        }
                    ]
                }
            ]
        };
    },
    mounted: function() {
        console.log(Storedata.methods.isadmin());
        console.log(Storedata.methods.isofficer());
        console.log(Storedata.methods.isofficersupport());
        if (!Storedata.methods.checkgrantccountonly()) {
            this.getlistnoti("account", "");
        }
        if (Storedata.methods.isadmin()) {
            this.getlistnoti("admin", "");
        }
        if (Storedata.methods.isofficer()) {
            this.getlistnoti("officer", "paymentaddtional");
        }
        if (Storedata.methods.issupport()) {
            this.getlistnoti("admin", "");
        }
        if (Storedata.methods.isofficersupport()) {
            this.getlistnoti("admin", "");
        }
    },
    computed: {
        sentnoti: function() {
            this.noti++;
        },
        defaultTitle: function() {
            return "ระบบหลังบ้าน";
        },
        user: function() {
            console.log(account_profile.value);
            // this should get from account store
            const profile = Cache.getCache("profile", profile);
            if (profile?.google?.islogin) {
                return {
                    img: profile?.google?.imageurl,
                    shortedName: profile?.google?.name?.charAt(0),
                    fullName: profile?.google?.name,
                    displayName: profile?.google?.email,
                    role: profile?.google?.role
                };
            }
            if (profile?.line?.islogin) {
                return {
                    img: profile?.line?.pictureUrl,
                    shortedName: profile?.line?.displayName?.charAt(0),
                    fullName: profile?.line?.displayName,
                    displayName: profile?.line?.displayName,
                    role: "(Line)"
                };
            }
            if (profile?.login?.islogin) {
                return {
                    img: profile?.login?.imageurl,
                    shortedName: profile?.login?.name?.charAt(0),
                    fullName: profile?.login?.name,
                    displayName: profile?.login?.email,
                    role: profile?.login?.role
                };
            }
            return null;
        },
        notificationCount: function() {
            return this.TopBarStore.state.notificationCount;
        }
    },
    methods: {
        testclick: function() {
            var info = {
                title: "แจ้งเตือน(รอบัญชีตรวจสอบการเงิน)",
                desc: "รอบัญชีตรวจสอบการเงิน ลำดับงาน 2998",
                url:
                    "/selllist??c=L02321&n=NisA159&ot=2989&so=SO-20230731063906#no-back-button",
                read: false,
                role: "account"
            };
            socket.emit("order", info);
        },
        hasSlot: function(name = "default") {
            return !!this.$slots[name];
        },
        toggleNotification: function() {
            if (this.notificationCount > 0) {
                this.TopBarStore.methods.setNotification(
                    !this.TopBarStore.state.notification
                );
            }
        },
        toggleUserAccountPanel: function() {
            this.TopBarStore.methods.setUserAccountPanel(
                !this.TopBarStore.state.userAccountPanel
            );
        },
        togglenotiaccount: function() {
            if (!Storedata.methods.checkgrantccountonly()) {
                this.getlistnoti("account", "");
            } else if (Storedata.methods.isadmin()) {
                this.getlistnoti("admin", "");
            } else if (Storedata.methods.isofficer()) {
                this.getlistnoti("officer", "paymentaddtional");
            } else if (Storedata.methods.issupport()) {
                this.getlistnoti("admin", "");
            } else if (Storedata.methods.isofficersupport()) {
                this.getlistnoti("admin", "");
            }
            // formStateStore.noti.count = 0
            this.updatenoti = true;
        },
        updatedata: function() {
            this.updatenoti = false;
        },
        getlistnoti: function(role, type) {
            var param = {
                Role: role,
                Type: type
            };

            BzbsUser.apigetnotimessage(param)
                .then(res => {
                    formStateStore.noti.count = res.data?.filter(
                        item => item.Read != 1
                    )?.length;
                    return (formStateStore.noti.listnoti = res.data);
                })

                .catch(error => {
                    console.log(error);
                });
        },
        gotostock: function() {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8081/อุอากะสะ"
                : "https://backofficestock.vercel.app/อุอากะสะ";
            window.location = path;
        },
        updatebugshowModal: function() {
            this.updatebugvisible = true;
        },
        updatebughandleOk: function() {
            console.log(e);
            this.updatebugvisible = false;
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/style/base/base.scss";

.top-bar {
    height: $topbar-height;
}
</style>
