<template>
    <div id="addproduct_id">
        <a-button type="primary" class="mb-2" @click="addproduct">
            <template #icon>
                <PlusCircleOutlined />
            </template>
            {{ textbtn }}
        </a-button>

        <!-- <pre>{{ formState.productlist }}</pre>
        <pre>{{ dataSource }}</pre> -->
        <!-- //modal สร้าง -->
        <div v-if="isclick == 'edit'">
            <a-modal
                :class="{
                    increasesize: is_edit_product.type == 'increasesize'
                }"
                v-model:visible="visible"
                width="1400px"
                style="top: 10px"
                :title="
                    is_edit_product.type == 'increasesize'
                        ? 'เพิ่มขนาดขนาด'
                        : 'สินค้า'
                "
                @ok="fromchange"
                :footer="null"
                @cancel="handleCancel"
            >
                <div>
                    <ListCreateproduct
                        :key="update"
                        :formStates="formStates"
                        :is_edit="is_edit_product"
                        :productjson="edit_productjson"
                        @closemodal="handleclosemodalproduct(e)"
                        v-model:value="formState.productlist"
                        :type="'edit'"
                        :sub_session_show="detail_sub_session_show"
                        :isscan="isscan"
                    >
                    </ListCreateproduct>
                </div>
            </a-modal>
        </div>
        <div v-if="isclick == 'stock'">
            <a-modal
                v-model:visible="visible"
                width="1400px"
                style="top: 10px"
                :title="
                    is_edit_product.type == 'increasestock'
                        ? 'เพิ่ม Stock'
                        : 'สินค้า'
                "
                @ok="fromchange"
                :footer="null"
                @cancel="handleCancel"
            >
                <div>
                    <ListCreateproduct
                        :key="update"
                        :formStates="formStates"
                        :is_edit="is_edit_product"
                        :productjson="edit_productjson"
                        @closemodal="handleclosemodalproduct(e)"
                        v-model:value="formState.productlist"
                        :type="'stock'"
                        :isscan="isscan"
                    >
                    </ListCreateproduct>
                </div>
            </a-modal>
        </div>

        <div v-if="isclick == 'add'">
            <a-modal
                v-model:visible="visible"
                width="1400px"
                style="top: 10px"
                title="สินค้า"
                @ok="fromchange"
                :footer="null"
                @cancel="handleCancel"
            >
                <ListCreateproduct
                    :key="update"
                    :formStates="formStates"
                    :is_edit="is_edit_product"
                    :productjson="edit_productjson"
                    @closemodal="handleclosemodalproduct(e)"
                    v-model:value="formState.productlist"
                    :type="'add'"
                    :isscan="isscan"
                >
                </ListCreateproduct>
            </a-modal>
        </div>

        <!-- //modal สร้าง -->
        <!-- //modal แก้ไข -->
        <!-- <a-modal v-model:visible="visibleEdit" width="1000px" title="Basic Modal" @ok="fromchange">
        </a-modal> -->
        <!-- //modal แก้ไข -->

        <!-- (1.1.29) -->
        <!-- <hr> -->
        <ScanFindProduct
            id="scanproduct"
            @ResultScan="onresultscan($event)"
        ></ScanFindProduct>
        <hr />
    </div>

    <!-- <pre>{{dataSource}}</pre> -->
    (1.1.1)

    <a-table
        class="product-zoom"
        id="id_of_table"
        :key="update"
        :columns="columns"
        :data-source="dataSource"
        bordered
        :scroll="{ x: 0 }"
        :pagination="{ pageSize: 50, hideOnSinglePage: true }"
        style="font-size:14px"
        :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
    >
        <template v-if="dataSource.length != 0" #summary>
            <a-table-summary-row>
                <a-table-summary-cell :col-span="2" class="text-center"
                    >ราคารวม</a-table-summary-cell
                >
                <a-table-summary-cell>
                    <a-typography-text type="danger">{{
                        showprice(totalBorrow)
                    }}</a-typography-text>
                </a-table-summary-cell>
                <a-table-summary-cell>
                    <a-typography-text type="danger">{{
                        showprice(totalDelivery)
                    }}</a-typography-text>
                </a-table-summary-cell>
                <a-table-summary-cell>
                    <a-typography-text type="danger">{{
                        showprice(totalLogoPrice)
                    }}</a-typography-text>
                </a-table-summary-cell>
                <a-table-summary-cell>
                    <a-typography-text style="color:blue"
                        >ส่วนลด
                        {{ showprice(totalDiscount)}}</a-typography-text
                    >
                </a-table-summary-cell>

                <a-table-summary-cell :col-span="8"> </a-table-summary-cell>
            </a-table-summary-row>
            <a-table-summary-row>
                <a-table-summary-cell :col-span="3" class="text-center"
                    >ราคาสุทธิ</a-table-summary-cell
                >
                <a-table-summary-cell :col-span="2" class="text-center">
                    <a-typography-text type="danger">
                        {{
                            showprice(
                                totalBorrow +
                                    totalDelivery +
                                    totalLogoPrice -
                                    totalDiscount
                            )
                        }}
                    </a-typography-text>
                </a-table-summary-cell>
                <a-table-summary-cell :col-span="8"> </a-table-summary-cell>
            </a-table-summary-row>
        </template>
        <template #bodyCell="{ column, text, record, index }">
            <div :class="{ 'c-gray': record.islog }">
                <template
                    v-if="['name', 'age', 'address'].includes(column.dataIndex)"
                >
                    <div>
                        <a-input
                            v-if="editableData[record.key]"
                            v-model:value="
                                editableData[record.key][column.dataIndex]
                            "
                            style="margin: -5px 0"
                        />
                        <template v-else>
                            {{ text }}
                        </template>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'key'">
                    <!-- record.islog : {{ record.islog }} -->
                    <div v-if="!record.islog">รายการที่ {{ record.key }}</div>
                    <div v-if="record.islog">
                        <a-steps progress-dot :current="1" direction="vertical">
                            <a-step title="ข้อมูลเดิมล่าสุด" />
                        </a-steps>
                        <a-tag v-if="false" color="#55acee">
                            <template #icon>
                                <partition-outlined />
                            </template>
                            ข้อมูลเดิม
                        </a-tag>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'price'">
                    <div>{{ showprice(record.userproduct.productprice) }}</div>
                </template>
                <template v-else-if="column.dataIndex === 'qty'">
                    <div>
                        {{ record.userproduct.qty || 1 }} ชิ้น
                        <div v-if="record.userproduct.discount">
                            <i style="color:blue"
                                >*ส่วนลด
                                {{ record.userproduct.discount || 0 }} บ.</i
                            >
                        </div>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'que'">
                    <div v-if="record.userproduct?.queyanimage?.length">
                        <div
                            v-if="Array.isArray(record.userproduct.queyanimage)"
                        >
                            <a-image
                                :width="100"
                                :src="
                                    record.userproduct.queyanimage[0].thumbUrl
                                "
                            />
                        </div>
                        <div v-else>
                            <div v-if="record.userproduct.queyanimage">
                                <a-image
                                    :width="100"
                                    :src="record.userproduct.queyanimage"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-show="record.userproduct.trackyan">
                        เลขแท็ก:{{ record.userproduct.trackyan }}
                    </div>

                    <div>{{ showthaidate(record.userproduct.queyandate) }}</div>
                </template>
                <!-- <template v-else-if="column.dataIndex === 'detail'">
                
                </template> -->

                <template v-else-if="column.dataIndex === 'userproduct'">
                    <div>
                        <!-- <pre>{{ record }}</pre> -->

                        <div v-show="record.userproduct.customerproductstype">
                            <font-awesome-icon
                                :icon="'fa-solid fa-cart-arrow-down'"
                            />
                            <span class="pl-2"
                                >{{ record.userproduct.customerproductstype }}
                                <span v-if="record.userproduct.stockuuid">
                                    <a-typography-text type="success"
                                        ><u
                                            @click="
                                                gotostockdetail(
                                                    record.userproduct.stockuuid
                                                )
                                            "
                                            >(ใช้ผืนสต๊อก)</u
                                        ></a-typography-text
                                    ></span
                                ></span
                            >
                        </div>
                        <div>{{ record.userproduct.heartyanname }}</div>
                        <div>{{ record.userproduct.customerproductssize }}</div>

                        <div
                            class="pt-2"
                            v-show="record.userproduct.productcode"
                        >
                            <font-awesome-icon :icon="'fa-solid fa-barcode'" />
                            {{ record.userproduct.productcode }}
                        </div>
                        <div>
                            <div
                                v-if="
                                    record.userproduct.professortype.length > 0
                                "
                            >
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '1'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">อาจารย์เลือกให้</a-tag>
                                    <!-- <a-typography-text>อาจารย์เลือกให้</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '2'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">รอคุยกับอาจารย์</a-tag>
                                    <!-- <a-typography-text>รอคุยกับอาจารย์</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '3'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">พรีออเดอร์</a-tag>
                                    <!-- <a-typography-text>พรีออเดอร์</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '4'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">ส่งเลย</a-tag>
                                    <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '6'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">จัดส่งพร้อมยันต์</a-tag>
                                    <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '7'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">จัดส่งทางไลน์</a-tag>
                                    <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '8'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">รับเอง</a-tag>
                                    <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '9'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">เก็บเงินปลายทาง</a-tag>
                                    <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '5'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">ขอรูปคู่อาจารย์</a-tag>
                                    <!-- <a-typography-text>ขอรูปคู่อาจารย์
                                    </a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '10'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50"
                                        >มัดจำแล้วรอคุยกับอาจารย์</a-tag
                                    >
                                    <!-- <a-typography-text>ขอรูปคู่อาจารย์
                                    </a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '11'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50">เพิ่มขนาดไม่ทัน</a-tag>
                                    <!-- <a-typography-text>ขอรูปคู่อาจารย์
                                    </a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '12'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#f50"
                                        >เพิ่มขนาดโดยใช้แทร็คใหม่</a-tag
                                    >
                                    <!-- <a-typography-text>ขอรูปคู่อาจารย์
                                    </a-typography-text> -->
                                </div>

                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '13'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="#000"
                                        >ย้ายสินค้าเข้าสต๊อก</a-tag
                                    >
                                    <!-- <a-typography-text>ขอรูปคู่อาจารย์
                                    </a-typography-text> -->
                                </div>
                                <div
                                    v-if="
                                        record.userproduct.professortype.includes(
                                            '14'
                                        )
                                    "
                                >
                                    <span style="color:red">*</span>
                                    <a-tag color="purple"
                                        >อาจารย์ออกแบบโลโก้</a-tag
                                    >
                                    <!-- <a-typography-text>ขอรูปคู่อาจารย์
                                    </a-typography-text> -->
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="item in record.userproduct.birthday"
                            :key="item.id"
                        >
                            <!-- {{item.ProductKey}}:{{index}} -->
                            <!-- <pre>{{ item.Birthday }}</pre> -->
                            <!-- <pre>{{ item.TypeBirthday }}</pre>
                            <pre>{{ item.YearBirthday }}</pre> -->
                            <div
                                class="pt-2"
                                v-if="
                                    item.ProductKey &&
                                        formatdate(item.Birthday) !=
                                            'Invalid date'
                                "
                            >
                                วันเกิด :{{ formatdatewithtype(item) }}
                            </div>
                            <div
                                class="pt-2"
                                v-if="item.ProductKey && item.YearBirthday"
                            >
                                ปีเกิด :{{ formatdatewithtype(item) }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'productyanimage'">
                    <div
                        v-if="Array.isArray(record.userproduct.productyanimage)"
                    >
                        <a-image
                            :width="100"
                            :src="
                                record.userproduct.productyanimage[0].thumbUrl
                            "
                        />
                    </div>
                    <div v-else>
                        <div v-if="record.userproduct.productyanimage">
                            <img
                                :src="record.userproduct.productyanimage"
                                class="img-fluid"
                            />
                            <!-- <a-image :width="100" :src="record.userproduct.productyanimage" /> -->
                        </div>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'paiddeliveryobj'">
                    <div v-if="record?.inside?.length" id="example-1">
                        <div v-for="item in record?.inside" :key="item.id">
                            {{ showprice(item.price) }}
                        </div>
                    </div>
                    <div v-if="record?.insidecod?.length" id="example-1">
                        <div v-for="item in record?.insidecod" :key="item.id">
                            {{ showprice(item.price) }}
                        </div>
                    </div>
                    <div v-if="record?.outside?.length" id="example-1">
                        <div v-for="item in record?.outside" :key="item.id">
                            {{ showprice(item.price) }}
                        </div>
                    </div>
                    <div v-if="record.islog">
                        -
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'designlogo'">
                    <div v-if="record?.userproduct?.logoprice" id="example-1">
                        {{ showprice(record?.userproduct?.logoprice) }}
                    </div>
                    <div class="text-center" v-else>
                        -
                    </div>
                </template>

                <template v-else-if="column.dataIndex === 'deliver'">
                    <div>
                        <div v-if="record?.inside?.length" id="example-1">
                            <div v-for="item in record?.inside" :key="item.id">
                                <div v-if="item.introduction">
                                    <a-typography-text underline
                                        >หมายเหตุจัดส่ง:
                                        {{
                                            item.introduction
                                        }}</a-typography-text
                                    >
                                </div>
                            </div>
                        </div>
                        <div v-if="record?.outside?.length" id="example-1">
                            <div v-for="item in record?.outside" :key="item.id">
                                <div v-if="item.introduction">
                                    <a-typography-text underline
                                        >หมายเหตุจัดส่ง:
                                        {{
                                            item.introduction
                                        }}</a-typography-text
                                    >
                                </div>
                            </div>
                        </div>
                        <br />
                        <div
                            v-if="
                                record.userproduct.professortype.includes('3')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark
                                >พรีออเดอร์</a-typography-text
                            >
                        </div>
                        <div
                            v-if="
                                record.userproduct.professortype.includes('4')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark>ส่งเลย</a-typography-text>
                        </div>
                        <div
                            v-if="
                                record.userproduct.professortype.includes('6')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark
                                >จัดส่งพร้อมยันต์</a-typography-text
                            >
                        </div>
                        <div
                            v-if="
                                record.userproduct.professortype.includes('7')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark
                                >จัดส่งทางไลน์</a-typography-text
                            >
                        </div>
                        <div
                            v-if="
                                record.userproduct.professortype.includes('8')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark>รับเอง</a-typography-text>
                        </div>
                        <div
                            v-if="
                                record.userproduct.professortype.includes('9')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark
                                >เก็บเงินปลายทาง</a-typography-text
                            >
                        </div>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'introductionyan'">
                    <div>
                        {{ record.userproduct.introductionyan }}

                        {{ record.userproduct?.introduction }}
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'productstatus'">
                    <div>
                        <a-typography-text
                            type="danger"
                            strong
                            v-if="record.userproduct.productstatus == 'cancel'"
                            >{{
                                record.userproduct.productstatus
                            }}</a-typography-text
                        >
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'productstatusdesc'">
                    <div>
                        <a-typography-text
                            type="success"
                            strong
                            v-if="record.userproduct.productstatus == 'cancel'"
                            >{{
                                record.userproduct.productstatusdesc
                            }}</a-typography-text
                        >
                    </div>
                </template>
                <!-- <template v-else-if="column.dataIndex === 'increasesize'">
                    <div v-if="!record.islog">
                        <span class="m-2" v-if="record.userproduct?.customerproductstype?.includes('ยันต์')">
                            <a-popconfirm v-if="dataSource.length" title="คุณต้องการเพิ่มขนาด?"
                                @confirm="onEdit(record,'increasesize')">
                                <fullscreen-outlined :style="{fontSize: '24px', color: '#08c',padding:'10px'}" />
                                <a>เพิ่มขนาด</a>
                            </a-popconfirm>
                        </span>
                        <span v-else class="danger">เพิ่มขนาดได้เฉพาะยันต์</span>
                    </div>
                </template> -->

                <template v-else-if="column.dataIndex === 'operation'">
                    <div v-if="!record.islog" class="editable-row-operations">
                        <div v-if="editableData[record.key]">
                            <a-typography-link @click="save(record.key)"
                                >Save</a-typography-link
                            >
                            <a-popconfirm
                                title="Sure to cancel?"
                                @confirm="cancel(record.key)"
                            >
                                <a>Cancel</a>
                            </a-popconfirm>
                        </div>
                        <div
                            v-if="
                                !record.islog &&
                                    Storedata.methods.checkgrantccountonly()
                            "
                        >
                            <a-popconfirm
                                v-if="
                                    record.userproduct?.customerproductstype?.includes(
                                        'ยันต์'
                                    )
                                "
                                title="คุณต้องการเพิ่มขนาด (*จะเกิดรายการเพิ่มขนาดขึ้นเมื่อเพิ่มขนาด)"
                                @confirm="onEdit(record, 'increasesize')"
                            >
                                <fullscreen-outlined
                                    :style="{
                                        fontSize: '24px',
                                        color: '#08c',
                                        padding: '10px'
                                    }"
                                />
                                <a><u>เพิ่มขนาด</u></a>
                                <hr />
                            </a-popconfirm>
                            <span v-else class="danger"
                                ><i>เพิ่มขนาดได้เฉพาะยันต์</i></span
                            >
                        </div>
                        <!-- <div
                            v-if="
                                !record.islog &&
                                    Storedata.methods.checkgrantccountonly()
                            "
                        >
                            <a-popconfirm
                                v-if="
                                    record.userproduct?.customerproductstype?.includes(
                                        'ยันต์'
                                    )
                                "
                                title="คุณต้องการเพิ่มขนาดโดยใช้แทร็คใหม่"
                                @confirm="onEdit(record, 'increasesize')"
                            >
                             
                                <NumberOutlined
                                    :style="{
                                        fontSize: '24px',
                                        color: '#08c',
                                        padding: '10px'
                                    }"
                                />
                                <a><u>เพิ่มขนาดโดยใช้แทร็คใหม่</u></a>
                                <hr />
                            </a-popconfirm>
                            <span v-else class="danger"
                                ><i>เพิ่มขนาดได้เฉพาะยันต์</i></span
                            >
                        </div> -->
                        <div>
                            <a-popconfirm
                                v-if="dataSource.length"
                                title="คุณต้องการแก้ไข?"
                                @confirm="
                                    onEdit(
                                        record,
                                        'edit',
                                        index,
                                        'insert_yandetail'
                                    )
                                "
                            >
                                <setting-outlined
                                    :style="{
                                        fontSize: '18px',
                                        color: '#08c',
                                        padding: '10px'
                                    }"
                                />
                                <a><u>แก้ไข</u></a>
                                <hr />
                            </a-popconfirm>
                        </div>
                        <div
                            v-if="
                                record.userproduct?.customerproductstype?.includes(
                                    'ยันต์'
                                )
                            "
                        >
                            <a-popconfirm
                                v-if="dataSource.length"
                                title="คุณต้องการแก้ไข?"
                                @confirm="
                                    onEdit(
                                        record,
                                        'edit',
                                        index,
                                        'insert_system'
                                    )
                                "
                            >
                                <setting-outlined
                                    :style="{
                                        fontSize: '18px',
                                        color: '#08c',
                                        padding: '10px'
                                    }"
                                />
                                <a><u>ลงระบบ</u></a>
                                <hr />
                            </a-popconfirm>
                        </div>

                        <!-- <div v-if="!record.islog && Storedata.methods.checkgrantccountonly()">
                            <a-popconfirm v-if="record.userproduct?.customerproductstype?.includes('ยันต์')"
                                title="คุณต้องการเพิ่ม STOCK?" @confirm="onEditStock(record, 'increasestock')">
                                <fullscreen-outlined :style="{ fontSize: '24px', color: '#08c', padding: '10px' }" />
                                <a><u>เพิ่ม Stock/แก้ไข</u></a>
                                <hr />
                            </a-popconfirm>
                            <span v-else class="danger"><i>เพิ่มขนาดได้เฉพาะยันต์</i></span>
                        </div> -->
                        <div>
                            <a-popconfirm
                                v-if="
                                    dataSource.length >= 2 &&
                                        record.userproduct.stockuuid == ''
                                "
                                title="คุณต้องการลบ?"
                                @confirm="
                                    onDelete(record.userproduct.productkey)
                                "
                            >
                                <delete-outlined
                                    :style="{
                                        fontSize: '18px',
                                        color: '#08c',
                                        padding: '10px'
                                    }"
                                />
                                <a><u>ลบ</u></a>
                                <hr />
                            </a-popconfirm>
                            <a-popconfirm
                                v-else-if="
                                    dataSource.length >= 2 &&
                                        record.userproduct.stockuuid != ''
                                "
                                title="คุณต้องการคืนสต๊อกสินค้า?"
                                @confirm="onReturnStock(record.userproduct)"
                            >
                                <delete-outlined
                                    :style="{
                                        fontSize: '18px',
                                        color: '#08c',
                                        padding: '10px'
                                    }"
                                />
                                <a><u>คืนสต๊อก</u></a>
                                <hr />
                            </a-popconfirm>
                        </div>
                    </div>
                    <div v-if="record.islog">
                        <a-button type="primary" @click="showModalHistory"
                            >ประวัติทั้งหมด</a-button
                        >
                        <a-modal
                            v-model:visible="visiblehistory"
                            width="1000px"
                            title="ประวัติทั้งหมดเพิ่มขนาด"
                            @ok="handleOkHistory"
                        >
                            <!-- <pre>{{ listhistorychangesize }}</pre> -->
                            <a-table
                                :dataSource="listhistorychangesize"
                                :columns="columnshistory"
                            >
                                <template
                                    #bodyCell="{ column, text, record, index }"
                                >
                                    <!-- <pre>{{ column.dataIndex }}</pre> -->

                                    <template v-if="column.dataIndex === 'key'">
                                        <!-- record.islog : {{ record.islog }} -->
                                        <div v-if="!record.islog">
                                            เพิ่มครั้งที่
                                            {{
                                                listhistorychangesize.length -
                                                    index
                                            }}
                                        </div>

                                        <a-typography-text mark>{{
                                            index == 0
                                                ? " (ก่อนครั้งล่าสุด)"
                                                : ""
                                        }}</a-typography-text>
                                    </template>
                                    <template
                                        v-if="
                                            column.dataIndex === 'userproduct'
                                        "
                                    >
                                        <div>
                                            <div>
                                                <!-- <pre>{{ record }}</pre> -->
                                                <div
                                                    v-show="
                                                        record.CustomerProductsType
                                                    "
                                                >
                                                    <font-awesome-icon
                                                        :icon="
                                                            'fa-solid fa-cart-arrow-down'
                                                        "
                                                    />
                                                    <span class="pl-2">{{
                                                        record.CustomerProductsType
                                                    }}</span>
                                                </div>
                                                <!-- <div>{{ record.userproduct.birthday }}</div> -->
                                                <div>
                                                    {{ record.HeartYanName }}
                                                </div>
                                                <div>
                                                    {{
                                                        record.CustomerProductsSize
                                                    }}
                                                </div>

                                                <div
                                                    class="pt-2"
                                                    v-show="record.ProductCode"
                                                >
                                                    <font-awesome-icon
                                                        :icon="
                                                            'fa-solid fa-barcode'
                                                        "
                                                    />
                                                    {{ record.ProductCode }}
                                                </div>
                                                <div>
                                                    <div
                                                        v-if="
                                                            record.ProfessorType
                                                                .length > 0
                                                        "
                                                    >
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '1'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >อาจารย์เลือกให้</a-tag
                                                            >
                                                            <!-- <a-typography-text>อาจารย์เลือกให้</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '2'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >รอคุยกับอาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>รอคุยกับอาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '5'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ขอรูปคู่อาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '10'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >มัดจำแล้วรอคุยกับอาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '11'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เพิ่มขนาดไม่ทัน</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '12'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เพิ่มขนาดโดยใช้แทร็คใหม่</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>

                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '13'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ย้ายสินค้าเข้าสต๊อก</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '14'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >อาจารย์ออกแบบโลโก้</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '3'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >พรีออเดอร์</a-tag
                                                            >
                                                            <!-- <a-typography-text>พรีออเดอร์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '4'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ส่งเลย</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '6'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >จัดส่งพร้อมยันต์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '7'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >จัดส่งทางไลน์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '8'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >รับเอง</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '9'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เก็บเงินปลายทาง</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '5'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ขอรูปคู่อาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template
                                        v-else-if="column.dataIndex === 'price'"
                                    >
                                        <div>
                                            {{ showprice(record.ProductPrice) }}
                                        </div>
                                    </template>
                                    <template
                                        v-else-if="
                                            column.dataIndex ===
                                                'introductionyan'
                                        "
                                    >
                                        <div>
                                            {{ record.IntroductionYan }}
                                        </div>
                                    </template>
                                    <template
                                        v-else-if="
                                            column.dataIndex === 'UpdateDate'
                                        "
                                    >
                                        <div>
                                            {{
                                                showthaidate(record.UpdateDate)
                                            }}
                                        </div>
                                    </template>
                                </template>
                            </a-table>
                        </a-modal>
                    </div>
                    <div v-if="false">
                        <a-button
                            v-if="
                                record.userproduct?.customerproductstype?.includes(
                                    'ยันต์'
                                )
                            "
                            type="primary"
                            @click="showModalHistoryStock"
                            >รายการสต๊อก(so)</a-button
                        >
                        <a-modal
                            v-model:visible="visiblehistorystock"
                            width="1000px"
                            title="รายการเพิ่ม Stock"
                            @ok="handleOkHistoryStock"
                        >
                            <a-table
                                :dataSource="listhistorystock"
                                :columns="columnstock"
                            >
                                <template
                                    #bodyCell="{ column, text, record, index }"
                                >
                                    <!-- <pre>{{ column.dataIndex }}</pre> -->

                                    <template v-if="column.dataIndex === 'key'">
                                        {{ index + 1 }}
                                    </template>
                                    <template
                                        v-if="
                                            column.dataIndex === 'userproduct'
                                        "
                                    >
                                        <div>
                                            <div>
                                                <!-- <pre>{{ record }}</pre> -->
                                                <div
                                                    v-show="
                                                        record.CustomerProductsType
                                                    "
                                                >
                                                    <font-awesome-icon
                                                        :icon="
                                                            'fa-solid fa-cart-arrow-down'
                                                        "
                                                    />
                                                    <span class="pl-2">{{
                                                        record.CustomerProductsType
                                                    }}</span>
                                                </div>

                                                <div>
                                                    {{ record.HeartYanName }}
                                                </div>
                                                <div>
                                                    {{
                                                        record.CustomerProductsSize
                                                    }}
                                                </div>

                                                <div
                                                    class="pt-2"
                                                    v-show="record.ProductCode"
                                                >
                                                    <font-awesome-icon
                                                        :icon="
                                                            'fa-solid fa-barcode'
                                                        "
                                                    />
                                                    {{ record.ProductCode }}
                                                </div>
                                                <div>
                                                    <div
                                                        v-if="
                                                            record.ProfessorType
                                                                .length > 0
                                                        "
                                                    >
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '1'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >อาจารย์เลือกให้</a-tag
                                                            >
                                                            <!-- <a-typography-text>อาจารย์เลือกให้</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '2'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >รอคุยกับอาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>รอคุยกับอาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '5'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ขอรูปคู่อาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '10'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >มัดจำแล้วรอคุยกับอาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '11'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เพิ่มขนาดไม่ทัน</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '12'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เพิ่มขนาดโดยใช้แทร็คใหม่</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>

                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '13'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ย้ายสินค้าเข้าสต๊อก</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '14'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >อาจารย์ออกแบบโลโก้</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>

                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '3'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >พรีออเดอร์</a-tag
                                                            >
                                                            <!-- <a-typography-text>พรีออเดอร์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '4'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ส่งเลย</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '6'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >จัดส่งพร้อมยันต์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '7'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >จัดส่งทางไลน์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '8'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >รับเอง</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '9'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เก็บเงินปลายทาง</a-tag
                                                            >
                                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '5'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ขอรูปคู่อาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '10'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >มัดจำแล้วรอคุยกับอาจารย์</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '11'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เพิ่มขนาดไม่ทัน</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '12'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >เพิ่มขนาดโดยใช้แทร็คใหม่</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '13'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >ย้ายสินค้าเข้าสต๊อก</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                        <div
                                                            v-if="
                                                                record.ProfessorType.includes(
                                                                    '14'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                style="color:red"
                                                                >*</span
                                                            >
                                                            <a-tag color="#f50"
                                                                >อาจารย์ออกแบบโลโก้</a-tag
                                                            >
                                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template
                                        v-else-if="column.dataIndex === 'price'"
                                    >
                                        <div>
                                            {{ showprice(record.ProductPrice) }}
                                        </div>
                                    </template>
                                    <template
                                        v-else-if="
                                            column.dataIndex ===
                                                'introductionyan'
                                        "
                                    >
                                        <div>
                                            {{ record.IntroductionYan }}
                                        </div>
                                    </template>
                                    <template
                                        v-else-if="
                                            column.dataIndex === 'OrderDate'
                                        "
                                    >
                                        <div>
                                            {{ showthaidate(record.OrderDate) }}
                                        </div>
                                    </template>
                                    <template
                                        v-else-if="
                                            column.dataIndex ===
                                                'IsUsedItemStock'
                                        "
                                    >
                                        <div
                                            v-if="
                                                record.IsUsedItemStock ==
                                                    'unused'
                                            "
                                        >
                                            <a-typography-text type="success"
                                                >ว่าง</a-typography-text
                                            >
                                        </div>
                                        <div
                                            v-else-if="
                                                record.IsUsedItemStock == 'used'
                                            "
                                        >
                                            <a-typography-text type="danger"
                                                >ใช้แล้ว</a-typography-text
                                            >
                                        </div>
                                    </template>

                                    <template
                                        v-else-if="
                                            column.dataIndex === 'Delete'
                                        "
                                    >
                                        <a-popconfirm
                                            v-if="dataSource.length >= 2"
                                            title="คุณต้องการลบ?"
                                            @confirm="onDeleteStock(record)"
                                        >
                                            <delete-outlined
                                                :style="{
                                                    fontSize: '18px',
                                                    color: '#08c',
                                                    padding: '10px'
                                                }"
                                            />
                                            <a><u>ลบ</u></a>
                                        </a-popconfirm>
                                    </template>
                                    <template
                                        v-else-if="column.dataIndex === 'Edit'"
                                    >
                                        <a-popconfirm
                                            v-if="dataSource.length >= 2"
                                            title="คุณต้องการแก้ไข?"
                                            @confirm="
                                                onEditStockHistory(
                                                    record,
                                                    'increasestock'
                                                )
                                            "
                                        >
                                            <a><u>แก้ไข</u></a>
                                        </a-popconfirm>
                                    </template>
                                </template>
                            </a-table>
                        </a-modal>
                    </div>
                </template>
            </div>
        </template>
    </a-table>
    <!-- <TableLoad v-else :isload="true"></TableLoad> -->

    <!-- formStates :
    <pre>{{formStates}}</pre>
    ===========================
    dataSource :
    <pre>{{dataSource}}</pre> -->
    <!-- //ตาราง -->
</template>
<script>
import {
    defineComponent,
    reactive,
    ref,
    watch,
    onMounted,
    computed
} from "vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import ListCreateproduct from "./ListCreateproduct.vue";
// import { formStateStore } from "../../../../../store/storebackoffice";
import _ from "lodash";
import { formStateStore } from "@/store/storebackoffice";

// import { formStatestore } from "@/store/store";
import TableLoad from "@/components/Skeleton/TableLoad";
import {
    FullscreenOutlined,
    SettingOutlined,
    DeleteOutlined,
    PartitionOutlined,
    DropboxOutlined,
    ArrowsAltOutlined,
    NumberOutlined
} from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { notification } from "ant-design-vue";
import moment from "moment";
import ScanFindProduct from "../ScanFindProduct.vue";
import Util from "@/helper/Utility.js";
import Storedata from "@/store/Storedata.js";

const columns = [
    {
        title: "ลำดับ",
        dataIndex: "key",
        width: "20%"
    },
    // {
    //     title: "รูป",
    //     dataIndex: "productyanimage",
    //     width: "18%",
    // },
    {
        title: "สินค้า",
        dataIndex: "userproduct",
        width: "40%"
    },
    // {
    //     title: "ข้อมูล",
    //     dataIndex: "detail",
    //     width: "20%"
    // },
    {
        title: "ราคาสินค้า",
        width: "15%",
        dataIndex: "price"
    },
    {
        title: "ค่าส่ง",
        width: "15%",
        dataIndex: "paiddeliveryobj"
    },
    {
        title: "ค่าออกแบบโลโก้",
        width: "15%",
        dataIndex: "designlogo"
    },
    {
        title: "จำนวน",
        width: "20%",
        dataIndex: "qty"
    },
    {
        title: "คิวเขียน",
        dataIndex: "que",
        width: "20%"
    },
    {
        title: "การจัดส่ง",
        width: "20%",
        dataIndex: "deliver"
    },
    // {
    //     title: "วันที่ยันต์มา",
    //     width: "15%",
    //     dataIndex: "dateyanarrival"
    // },
    {
        title: "หมายเหตุ",
        width: "15%",
        dataIndex: "introductionyan"
    },
    // {
    //     title: "สถานะ",
    //     width: "15%",
    //     // fixed: 'right',
    //     dataIndex: "productstatus"
    // },
    // {
    //     title: "สถานะ(รายละเอียด)",
    //     width: "20%",
    //     // fixed: 'right',
    //     dataIndex: "productstatusdesc"
    // },
    // {
    //     title: "เพิ่มขนาด",
    //     width: "20%",
    //     fixed: 'right',
    //     dataIndex: "increasesize"
    // },
    {
        title: "จัดการ",
        dataIndex: "operation",
        fixed: "right",
        width: "30%"
    }
];

var columnshistory = [
    {
        title: "ลำดับ",
        dataIndex: "key",
        width: "18%"
    },
    {
        title: "สินค้า",
        dataIndex: "userproduct",
        width: "40%"
    },
    {
        title: "ราคาสินค้า",
        width: "15%",
        dataIndex: "price"
    },
    {
        title: "วันที่เพิ่มขนาด",
        width: "15%",
        dataIndex: "UpdateDate"
    }
];

var columnstock = [
    {
        title: "ลำดับ",
        dataIndex: "key",
        width: "5%"
    },
    {
        title: "สินค้า",
        dataIndex: "userproduct",
        width: "40%"
    },
    {
        title: "ราคาสินค้า",
        width: "15%",
        dataIndex: "price"
    },
    {
        title: "วันที่เพิ่ม stok",
        width: "15%",
        dataIndex: "OrderDate"
    },
    {
        title: "สถานะ",
        width: "15%",
        dataIndex: "IsUsedItemStock"
    },
    {
        title: "ลบ",
        width: "15%",
        dataIndex: "Delete"
    },
    {
        title: "แก้ไข",
        width: "15%",
        dataIndex: "Edit"
    }
];
const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        PartitionOutlined,
        SettingOutlined,
        DeleteOutlined,
        FullscreenOutlined,
        TableLoad,
        // SelectOption,
        // Address,
        // PlusCircleOutlined,
        ListCreateproduct,
        ScanFindProduct,
        DropboxOutlined,
        ArrowsAltOutlined,
        NumberOutlined
    },
    props: {
        textbtn: null,
        formStates: null
    },
    setup(props, { emit }) {
        const visiblehistory = ref(false);
        const visiblehistorystock = ref(false);
        const isscan = ref(false);
        const detail_sub_session_show = ref("");
        const isclick = ref("edit");
        const visible = ref(false);
        const visibleEdit = ref(false);
        const listhistorychangesize = ref([]);
        const listhistorystock = ref([]);
        const dataSource = ref([]);
        const is_edit_product = ref({
            fact: false,
            key: 0,
            type: "",
            iseditstock: false,
            iseditstock__uuid: ""
        });

        var edit_productjson = ref(null);
        const formStates = reactive(props.formStates);

        onMounted(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get("p");
            if (
                myParam != "create" &&
                window.location.pathname == "/sellcreate"
            ) {
                window.location = "/sellcreate?p=create";
            }

            getCustomerProductlist();
        });
        var countlist = 1;
        const getCustomerProductlist = async () => {
            if (is_edit_product.value.type == "increasestock") {
                var sellordertransaction =
                    props.formStates.user.sellordertransaction;
                if (is_edit_product.value.iseditstock == true) {
                    sellordertransaction =
                        is_edit_product.value.iseditstock__uuid;
                    getproducstockdetail(sellordertransaction);
                } else {
                    getproducstocklist(sellordertransaction);
                }
            } else {
                debugger
                await BzbsUser.apigetproductbyid(
                    props.formStates.user.sellordertransaction
                )
                    .then(res => {
                        countlist = 1;
                        console.log("CustomerProductbyCustomercode", res.data);
                        formStateStore.listproduct = [];

                        dataSource.value = [];

                        formStateStore.listproduct = res.data;
                        res.data.forEach(items => {
                            var result = _.transform(items, function(
                                result,
                                val,
                                key
                            ) {
                                result[key.toLowerCase()] = val;
                            });

                            handleclosemodal(result);
                            setTimeout(() => {
                                totalBorrow.value = 0;
                                totalDelivery.value = 0;
                                totalLogoPrice.value = 0;
                                debugger
                                totalDiscount.value = 0;
                                funtotals();
                                funtotalsdelivery();
                                funtotalslogoprice();
                                update.value++;
                            }, 1000);
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const getproducstocklist = async sellordertransaction => {
            await BzbsUser.apigetproducstocktbyid(sellordertransaction)
                .then(res => {
                    if (res?.data?.length == 0 || _.isEmpty(res)) {
                        showModalStock();

                        res.data?.forEach(items => {
                            var result = _.transform(items, function(
                                result,
                                val,
                                key
                            ) {
                                result[key.toLowerCase()] = val;
                            });

                            handleclosemodalstock(result);
                        });
                    } else {
                        showModalStock();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        // const getproducstockdetail = async (pk) => {
        //     await BzbsUser.apigetproductstockdetail(pk)
        //         .then(res => {

        //             if (res.data.length == 0) return showModalStock()

        //             res.data.forEach(items => {
        //                 var result = _.transform(items, function (
        //                     result,
        //                     val,
        //                     key
        //                 ) {
        //                     result[key.toLowerCase()] = val;
        //                 });

        //                 handleclosemodalstock(result);

        //             });
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });

        // }

        const getproducstockdetail = async pk => {
            try {
                const res = await BzbsUser.apigetproductstockdetail(pk);

                if (res.data.length === 0) {
                    showModalStock();
                } else {
                    res.data.forEach(items => {
                        var result = _.transform(items, function(
                            result,
                            val,
                            key
                        ) {
                            result[key.toLowerCase()] = val;
                        });

                        handleclosemodalstock(result);
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        const showModal = () => {
            update.value++;
            isclick.value = "edit";
            visible.value = true;
        };
        const showModalStock = () => {
            update.value++;
            isclick.value = "stock";
            visible.value = true;

            // clearuserproductstock()
        };
        const showModalIncreasesize = () => {
            isclick.value = "edit";
            visible.value = true;
        };

        const addproduct = () => {
            clearuserproduct();
            console.log("addddd ", formState.userproduct);
            isclick.value = "add";
            is_edit_product.value.type = "add";
            is_edit_product.value.fact = false;
            visible.value = true;
            update.value++;
        };
        const clearuserproduct = () => {
            formStates.userproduct = {
                heartyanname: "",
                queyan: "1",
                professortype: [],
                inside: [],
                insidecod: [],
                outside: [],
                introductionyan: "",
                isgencode: false,
                addressfrom: [],
                contactList: [],
                customerproductstype: "",
                selectcountry: { selectcountry: "TH" },
                ordertransaction: "",
                customeraddressType: "1"
            };
        };
        const clearuserproductstock = () => {
            formStates.userproduct.heartyanname = "";
            formStates.userproduct.queyan = "1";
            formStates.userproduct.professortype = [];
            formStates.userproduct.inside = [];
            formStates.userproduct.insidecod = [];
            formStates.userproduct.outside = [];
            formStates.userproduct.introductionyan = "";
            formStates.userproduct.isgencode = false;
            formStates.userproduct.addressfrom = [];
            formStates.userproduct.contactList = [];
            formStates.userproduct.selectcountry = { selectcountry: "TH" };
            formStates.userproduct.ordertransaction = "";
            formStates.userproduct.customeraddressType = "1";

            formState.userproduct.systemtrackyan = "";
            formState.userproduct.systemyandate = "";
            formState.userproduct.systemyanimage = "";
            formState.userproduct.systemchecktabactive_1 = [];
            formState.userproduct.systemchecktabactive_2 = [];
            formState.userproduct.systemchecktabactive_3 = [];
        };
        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const update = ref(1);

        const radioStyle = reactive({});
        const productfrom = reactive({});
        const formState = reactive({
            useraddress: {
                key: 0,
                typeaddress: "1",
                selectcountry: "TH"
            },
            productlist: [],
            listproduct: []
        });
        const list = reactive({
            listproduct: []
        });
        watch(
            () => formState.useraddress.selectcountry.selectcountry,
            () => {
                try {
                    if (
                        listcodephone.value.find(
                            word =>
                                word.value ==
                                formState.useraddress.selectcountry
                                    .selectcountry
                        )
                    ) {
                        formState.useraddress.phonecode = listcodephone.value.filter(
                            word =>
                                word.value ==
                                formState.useraddress.selectcountry
                                    .selectcountry
                        );
                    }
                } catch (error) {}
            },
            () => formState.productlist,
            () => {
                console.log(formState.productlist);
            }
        );
        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            if (visibleEdit.value) {
                // formState.listproduct[editIndex.value] = formState.useraddress;
            }

            emit("update:value", formState);
            formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };
        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
        };

        console.log("list.listproduct ", list.listproduct);

        const editableData = reactive({});
        const editIndex = ref();
        const edit = key => {
            editIndex.value = key;
            // visibleEdit.value = true;
            showModal();
        };

        const save = key => {
            console.log(key);
            // Object.assign(
            //     dataSource.value.filter(item => key === item.key)[0],
            //     editableData[key]
            // );
            // delete editableData[key];
        };

        const cancel = key => {
            delete editableData[key];
        };
        const onReturnStock = async item => {
            if (item.stockuuid) {
                var stock_params = {
                    Stockuuid: item.stockuuid,
                    ProductKey: item.productkey,
                    ProductSellOrder: item.productsellorder
                };

                await BzbsUser.apireturnproductstock(stock_params)
                    .then(async res => {
                        console.log(res);
                        window.location.reload();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const onDelete = key => {
            // dataSource.value = dataSource.value.filter(
            //     item => item.key !== key
            // );

            var index = dataSource.value.findIndex(
                e => e.userproduct.productkey === key
            );
            calldeleteproduct(dataSource.value[index]);
            dataSource.value.splice(
                dataSource.value.findIndex(
                    e => e.userproduct.productkey === key
                ),
                1
            );

            formStateStore.listproduct = [];
            dataSource.value.forEach(element => {
                formStateStore.listproduct.push(element.userproduct);
            });

            console.log(
                "formStateStore.listproduct :",
                formStateStore.listproduct
            );
            update.value++;
        };

        const onDeleteStock = recode => {
            calldeleteproductstock(recode);
        };

        const onEditStockHistory = (info, type) => {
            formStateStore.is_edit_product = type;

            is_edit_product.value.iseditstock = true;
            is_edit_product.value.iseditstock__uuid = info.ProductKey;
            visiblehistorystock.value = false;
            is_edit_product.value.type = "increasestock";

            getCustomerProductlist();

            update.value++;
        };

        const calldeleteproductstock = recode => {
            var param_del = {
                CustomerOrder: recode.CustomerOrder,
                ProductSellOrder: recode.ProductSellOrder,
                ProductKey: recode.ProductKey
            };

            console.log("param_del :", param_del);
            BzbsUser.deleteproductstock(param_del)
                .then(res => {
                    openNotification("ลบรายการ Stock เรียบร้อย");
                    gethistorystock();
                    // setTimeout(() => {
                    //     getCustomerProductlist();
                    // }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const calldeleteproduct = item => {
            console.log("remove product:", item);

            var param_del = {
                CustomerOrder: item.userproduct.customerorder,
                ProductSellOrder: formStateStore.user.sellordertransaction,
                ProductKey: item.userproduct.productkey
            };
            console.log("param_del : ", param_del);

            BzbsUser.deleteproduct(param_del)
                .then(res => {
                    openNotification("ลบรายการเรียบร้อย");
                    console.log(res);
                    setTimeout(() => {
                        getCustomerProductlist();
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onEdit = (info, type, index, sub_session_show) => {
            debugger
            detail_sub_session_show.value = sub_session_show;
            formStateStore.userproduct = info.userproduct;
            formState.userproduct = info.userproduct;
            formStateStore.is_edit_product = type;
            console.log("info formStateStore: ", formStateStore.userproduct);
            console.log("info formState: ", formState.userproduct);
            is_edit_product.value.type = type;
            is_edit_product.value.fact = true;
            is_edit_product.value.key = info.userproduct.productkey;
            is_edit_product.value.rowkey = index;

            formStateStore.inside = info.inside;
            formStateStore.insidecod = info.insidecod;
            formStateStore.outside = info.outside;
            showModal();
            debugger
            formState.listproduct = dataSource.value;

            update.value++;
        };

        const onEditStock = (info, type) => {
            formStateStore.userproduct = info.userproduct;
            formState.userproduct = info.userproduct;
            formStateStore.is_edit_product = type;
            console.log("info formStateStore: ", formStateStore.userproduct);
            console.log("info formState: ", formState.userproduct);
            is_edit_product.value.type = type;
            is_edit_product.value.fact = true;
            is_edit_product.value.key = info.userproduct.productkey;

            formStateStore.inside = info.inside;
            formStateStore.outside = info.outside;

            getCustomerProductlist();
debugger
            formState.listproduct = dataSource.value;
            update.value++;
        };

        const increasesize = info => {
            console.log("info : ", info);
            is_edit_product.value.fact = true;
            is_edit_product.value.key = info.key - 1;
            formStateStore.userproduct = info.userproduct;

            formStateStore.inside = info.inside;
            formStateStore.insidecod = info.insidecod;
            formStateStore.outside = info.outside;
            showModalIncreasesize();

            debugger
            formState.listproduct = dataSource.value;
            update.value++;
        };

        let resulttemp = [];
        const handleclosemodal = e => {
            try {
                debugger
                console.log("productcode_", e.productcode);

                if (e == undefined) {
                    visible.value = false;
                    return;
                }

                var inside = [];
                var insidecod = [];
                var outside = [];

                if (e.pd_deliverytype == "inside") {
                    inside = [
                        {
                            price: e.pd_price,
                            id: e.pd_id,
                            introduction: e.pd_introduction
                        }
                    ];
                }
                if (e.pd_deliverytype == "insidecod") {
                    insidecod = [
                        {
                            price: e.pd_price,
                            id: e.pd_id,
                            introduction: e.pd_introduction
                        }
                    ];
                }
                if (e.pd_deliverytype == "outside") {
                    outside = [
                        {
                            price: e.pd_price,
                            id: e.pd_id,
                            introduction: e.pd_introduction
                        }
                    ];
                }
                var set_product = [];
                var ProductChangeLog = [];
                if (e.productchangelog != null) {
                    JSON.parse(e.productchangelog).forEach(element => {
                        var itemchild = {
                            islog: true,
                            key: "",
                            userproduct: set_product
                        };
                        set_product = _.transform(element, function(
                            result,
                            val,
                            key
                        ) {
                            result[key?.toLowerCase()] = val;
                        });
                        if (element.ProductKey != e.productkey) return;
                        itemchild.userproduct = set_product;
                        ProductChangeLog.push(itemchild);
                    });
                }

                let product = {};

                try {
                    // Base properties
                    product.key = countlist;
                    product.inside = inside;
                    product.insidecod = insidecod;
                    product.outside = outside;

                    // userproduct properties
                    product.userproduct = {};

                    try {
                        product.userproduct.setting_issentnotiqueuecustomer =
                            e.setting_issentnotiqueuecustomer;
                        product.userproduct.setting_issentnoticomingyancustomer =
                            e.setting_issentnoticomingyancustomer;
                        product.userproduct.issentnotiqueuecustomer =
                            e.issentnotiqueuecustomer;
                        product.userproduct.issentnoticomingyancustomer =
                            e.issentnoticomingyancustomer;
                        product.userproduct.stockuuid = e.stockuuid;
                        product.userproduct.logoprice = e.logoprice;
                        product.userproduct.productlogoimage =
                            e.productlogoimage;
                        product.userproduct.usercommission = Util.jsonParseArray(
                            e.usercommission || "[]"
                        );
                        product.userproduct.quantitystock = e.quantitystock;
                        product.userproduct.shotness = e.shotness;
                        product.userproduct.category_1 = e.category_1;
                        product.userproduct.category_2 = e.category_2;
                        product.userproduct.category_3 = e.category_3;
                        product.userproduct.category_4 = e.category_4;
                        product.userproduct.category_1_code = e.category_1_code;
                        product.userproduct.category_2_code = e.category_2_code;
                        product.userproduct.category_3_code = e.category_3_code;
                        product.userproduct.category_4_code = e.category_4_code;
                        product.userproduct.systemchecktabactive_1 =
                            e.systemchecktabactive_1;
                        product.userproduct.systemchecktabactive_2 =
                            e.systemchecktabactive_2;
                        product.userproduct.systemchecktabactive_3 =
                            e.systemchecktabactive_3;
                        product.userproduct.remindstatus = e.remindstatus;
                        product.userproduct.remindsentcomingdesc =
                            e.remindsentcomingdesc;
                        product.userproduct.remindsentdesc = e.remindsentdesc;
                        product.userproduct.remindnotpaydelivery =
                            e.remindnotpaydelivery;
                        product.userproduct.customernotconfirmaddressalready =
                            e.customernotconfirmaddressalready;
                        product.userproduct.customerconfirmaddressalready =
                            e.customerconfirmaddressalready;
                        product.userproduct.comingyanimage = e.comingyanimage;
                        product.userproduct.addresskey = e.addresskey;
                        product.userproduct.qty = e.qty;
                        product.userproduct.increasesizedesc =
                            e.increasesizedesc;
                        product.userproduct.increasesizeorder =
                            e.increasesizeorder;
                        product.userproduct.ordertransaction =
                            e.ordertransaction;
                        product.userproduct.ordertransactionfrom =
                            e.ordertransactionfrom;
                        product.userproduct.queyan = e.queyan;
                        product.userproduct.trackyan = e.trackyan;
                        product.userproduct.systemtrackyan = e.systemtrackyan;
                        product.userproduct.birthday = Util.jsonParse(
                            e.birthday || "[]"
                        );
                        product.userproduct.queyanimage = e.queyanimage;
                        product.userproduct.systemyanimage = e.systemyanimage;
                        product.userproduct.queyandate = e.queyandate;
                        product.userproduct.systemyandate = e.systemyandate;
                        product.userproduct.yancomingdate = e.yancomingdate;
                        product.userproduct.professortype = JSON.parse(
                            e.professortype || "[]"
                        );
                        product.userproduct.deliverytype = e.deliverytype;
                        product.userproduct.customerproductstype =
                            e.customerproductstype;
                        product.userproduct.productprice = e.productprice;
                        product.userproduct.originalprice = e.originalprice;
                        product.userproduct.discount = e.discount;
                        product.userproduct.productcode = e.productcode;
                        product.userproduct.productsellorder =
                            e.productsellorder;
                        product.userproduct.customerorder = e.customerorder;
                        product.userproduct.productkey = e.productkey;
                        product.userproduct.customerproductssize =
                            e.customerproductssize;
                        product.userproduct.heartyanname = e.heartyanname;
                        product.userproduct.productdetail = JSON.parse(
                            e.productdetail || "[]"
                        );
                        product.userproduct.introductionyan = e.introductionyan;
                        product.userproduct.phone = e.phone;
                        product.userproduct.p_phoneemergency =
                            e.p_phoneemergency;
                        product.userproduct.productyanimage = e.productyanimage;
                        product.userproduct.productyanimagebase64 =
                            e.productyanimage;
                        product.userproduct.introduction = e.introduction;
                        product.userproduct.email = e.email;
                        product.userproduct.career = e.career;
                        product.userproduct.introductioncareer =
                            e.introductioncareer;
                        product.userproduct.lineid = e.lineid;
                        product.userproduct.facebook = e.facebook;
                        product.userproduct.customerphonethai =
                            e.customerphonethai;
                        product.userproduct.productstatus = e.productstatus;
                        product.userproduct.productstatusdesc =
                            e.productstatusdesc;
                        product.userproduct.customername = e.customername;
                        product.userproduct.customerlastname =
                            e.customerlastname;
                        product.userproduct.customernickname =
                            e.customernickname;
                        product.userproduct.customerbirthdaytype =
                            e.customerbirthdaytype;
                        product.userproduct.customerage = e.customerage;
                        product.userproduct.customergender = e.customergender;
                        product.userproduct.customerbirthday =
                            e.customerbirthday;
                        product.userproduct.customercareer = e.customercareer;
                        product.userproduct.customerlineid = e.customerlineid;
                        product.userproduct.customerfacebook =
                            e.customerfacebook;
                        product.userproduct.addressdesc = e.customeraddress;
                        product.userproduct.provincepostinter =
                            e.provincepostinter;
                        product.userproduct.citypostinter = e.citypostinter;
                        product.userproduct.postcodepostinter =
                            e.postcodepostinter;
                        product.userproduct.customerphone = e.customerphone;
                        product.userproduct.customeremail = e.customeremail;
                        product.userproduct.DistrictName = e.districtname;
                        product.userproduct.PostCode = e.postcode;
                        product.userproduct.selectcountry = {
                            selectcountry: e.customerselectcountry,
                            selectcountrycode: e.customerselectcountrycode
                        };
                        product.userproduct.ProvinceName = e.provincename;
                        product.userproduct.BorderName = e.bordername;
                        product.userproduct.CustomerSelectCountry =
                            e.customerselectcountry;
                        product.userproduct.CustomerSelectcountryCode =
                            e.customerselectcountrycode;
                        product.userproduct.p_customerphoneemergency =
                            e.p_customerphoneemergency;
                        product.userproduct.p_customerdesc = e.p_customerdesc;
                        product.userproduct.customerphone = e.customerphone;
                        product.userproduct.customeraddressType =
                            e.p_customeraddresstype || e.customeraddresstype;
                        product.userproduct.customerinternationalphone =
                            e.customerinternationalphone;
                        product.userproduct.orderdate = e.orderdate;
                    } catch (error) {
                        debugger;
                        console.error("Error constructing userproduct:", error);
                        // Optionally include more context if needed
                    }

                    // Other properties
                    product.sellorderTransaction = e.sellorderTransaction;
                    product.orderdate = e.orderdate;
                    product.productjson = e.productjson;
                    product.children =
                        ProductChangeLog.length === 0 ? null : ProductChangeLog;
                } catch (error) {
                    debugger;
                    console.error("Error constructing product:", error);
                }

                // formStates.userproduct = product.userproduct

                console.log("product :", product);
                console.log("formState.userproduct", formState.userproduct);

                dataSource.value.push(product);
debugger
                formState.listproduct = dataSource.value;
                formState.productlist.listproduct = dataSource.value;
                countlist = countlist + 1;
                update.value++;
            } catch (error) {
                console.log(error);
            }
        };
        const handleclosemodalstock = e => {
            debugger
            if (e == undefined) {
                visible.value = false;
                return;
            }

            var inside = [];
            var outside = [];

            if (e.pd_deliverytype == "inside") {
                inside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            if (e.pd_deliverytype == "outside") {
                outside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            var set_product = [];

            var product = {
                key: countlist,
                inside: inside,
                outside: outside,
                userproduct: {
                    stockuuid: e.stockuuid,
                    logoprice: e.logoprice,
                    productlogoimage: e.productlogoimage,
                    usercommission: e.usercommission,
                    quantitystock: e.quantitystock,
                    shotness: e.shotness,
                    stockuuid: e.stockuuid,
                    category_1: e.category_1,
                    category_2: e.category_2,
                    category_3: e.category_3,
                    category_4: e.category_4,
                    category_1_code: e.category_1_code,
                    category_2_code: e.category_2_code,
                    category_3_code: e.category_3_code,
                    category_4_code: e.category_4_code,
                    systemchecktabactive_1: e.systemchecktabactive_1,
                    systemchecktabactive_2: e.systemchecktabactive_2,
                    systemchecktabactive_3: e.systemchecktabactive_3,
                    remindstatus: e.remindstatus,
                    remindsentcomingdesc: e.remindsentcomingdesc,
                    remindsentdesc: e.remindsentdesc,
                    remindnotpaydelivery: e.remindnotpaydelivery,
                    customernotconfirmaddressalready:
                        e.customernotconfirmaddressalready,
                    customerconfirmaddressalready:
                        e.customerconfirmaddressalready,
                    comingyanimage: e.comingyanimage,
                    addresskey: e.addresskey,
                    qty: e.qty,
                    increasesizedesc: e.increasesizedesc,
                    increasesizeorder: e.increasesizeorder,
                    ordertransaction: e.ordertransaction,
                    ordertransactionfrom: e.ordertransactionfrom,
                    queyan: e.queyan,
                    trackyan: e.trackyan,
                    systemtrackyan: e.systemtrackyan,
                    birthday: Util.jsonParse(e.birthday),
                    queyanimage: e.queyanimage,
                    systemyanimage: e.systemyanimage,
                    queyandate: e.queyandate,
                    systemyandate: e.systemyandate,
                    yancomingdate: e.yancomingdate,
                    professortype: JSON.parse(e.professortype || []),
                    deliverytype: e.deliverytype,
                    customerproductstype: e.customerproductstype,
                    productprice: e.productprice,
                    originalprice: e.originalprice,
                    discount: e.discount,
                    productcode: e.productcode,
                    productsellorder: e.productsellorder,
                    customerorder: e.customerorder,
                    productkey: e.productkey,
                    customerproductssize: e.customerproductssize,
                    heartyanname: e.heartyanname,
                    productdetail: JSON.parse(e.productdetail || []),
                    introductionyan: e.introductionyan,
                    phone: e.phone,
                    p_phoneemergency: e.p_phoneemergency,
                    productyanimage: e.productyanimage,
                    productyanimagebase64: e.productyanimage,
                    introduction: e.introduction,
                    email: e.email,
                    career: e.career,
                    introductioncareer: e.introductioncareer,
                    lineid: e.lineid,
                    facebook: e.facebook,
                    customerphonethai: e.customerphonethai,
                    productstatus: e.productstatus,
                    productstatusdesc: e.productstatusdesc,
                    customername: e.customername,
                    customerlastname: e.customerlastname,
                    customernickname: e.customernickname,
                    customerbirthdaytype: e.customerbirthdaytype,
                    customerage: e.customerage,
                    customergender: e.customergender,
                    customerbirthday: e.customerbirthday,
                    customercareer: e.customercareer,
                    customerlineid: e.customerlineid,
                    customerfacebook: e.customerfacebook,
                    addressdesc: e.customeraddress,
                    provincepostinter: e.provincepostinter,
                    citypostinter: e.citypostinter,
                    postcodepostinter: e.postcodepostinter,
                    customerphone: e.customerphone,
                    customeremail: e.customeremail,
                    DistrictName: e.districtname,
                    PostCode: e.postcode,
                    selectcountry: {
                        selectcountry: e.customerselectcountry,
                        selectcountrycode: e.customerselectcountrycode
                    },
                    ProvinceName: e.provincename,
                    BorderName: e.bordername,
                    CustomerSelectCountry: e.customerselectcountry,
                    CustomerSelectcountryCode: e.customerselectcountrycode,
                    p_customerphoneemergency: e.p_customerphoneemergency,
                    p_customerdesc: e.p_customerdesc,
                    customerphone: e.customerphone,
                    customeraddressType:
                        e.p_customeraddresstype || e.customeraddresstype,
                    customerinternationalphone: e.customerinternationalphone,
                    orderdate: e.orderdate

                    // children: ProductChangeLog,
                },
                sellorderTransaction: e.sellorderTransaction,
                orderdate: e.orderdate,
                productjson: e.productjson,
                children: null
            };
            formStates.userproduct = product.userproduct;

            showModalStock();
            // console.log("product :", product)
            // console.log("formState.userproduct", formState.userproduct)

            // dataSource.value.push(product);

            // formState.productlist.listproduct = dataSource.value
            // countlist = countlist + 1;
            // update.value++;
        };

        const handleclosemodalproduct = e => {
            debugger
            visible.value = false;

            // dataSource.value = [];

            try {
                formState.productlist.listproduct.forEach((items, index) => {
                    var result = _.transform(items, function(result, val, key) {
                        result[key.toLowerCase()] = val;
                    });

                    var inside = [];
                    var insidecod = [];
                    var outside = [];
                    if (result.pd_deliverytype == "inside") {
                        inside = [
                            {
                                price: result.pd_price,
                                id: result.pd_id,
                                introduction: result.pd_introduction
                            }
                        ];
                    }
                    if (result.pd_deliverytype == "insidecod") {
                        insidecod = [
                            {
                                price: result.pd_price,
                                id: result.pd_id,
                                introduction: result.pd_introduction
                            }
                        ];
                    }
                    // else {
                    //     inside = formState.productlist.inside
                    // }
                    if (result.pd_deliverytype == "outside") {
                        outside = [
                            {
                                price: result.pd_price,
                                id: result.pd_id,
                                introduction: result.pd_introduction
                            }
                        ];
                    }
                    // else {
                    //     outside = formState.productlist.outside
                    // }
                    if (result.inside?.length > 0) inside = result.inside;
                    if (result.insidecod?.length > 0)
                        insidecod = result.insidecod;
                    if (result.outside?.length > 0) outside = result.outside;

                    console.log(
                        "formState.productlist.listproductlogchange",
                        formState.productlist.listproductlogchange
                    );

                    var set_product = [];
                    var ProductChangeLog = [];

                    if (
                        false &&
                        result.productchangelog &&
                        formStateStore.is_edit_product == "increasesize"
                    ) {
                        JSON.parse(result.productchangelog).forEach(element => {
                            var itemchild = {
                                islog: true,
                                key: "",
                                userproduct: set_product
                            };
                            set_product = _.transform(element, function(
                                result,
                                val,
                                key
                            ) {
                                result[key?.toLowerCase()] = val;
                            });
                            itemchild.userproduct = set_product;
                            ProductChangeLog.push(itemchild);
                        });
                    }

                    var product = {
                        key: index + 1,
                        inside: inside,
                        insidecod: insidecod,
                        outside: outside,
                        userproduct: {
                            stockuuid: result.stockuuid,
                            logoprice: result.logoprice,
                            productlogoimage: result.productlogoimage,
                            usercommission: result.usercommission,
                            quantitystock: result.quantitystock,
                            shotness: result.shotness,
                            stockuuid: result.stockuuid,
                            category_1: result.category_1,
                            category_2: result.category_2,
                            category_3: result.category_3,
                            category_4: result.category_4,
                            category_1_code: result.category_1_code,
                            category_2_code: result.category_2_code,
                            category_3_code: result.category_3_code,
                            category_4_code: result.category_4_code,
                            systemchecktabactive_1:
                                result.systemchecktabactive_1,
                            systemchecktabactive_2:
                                result.systemchecktabactive_2,
                            systemchecktabactive_3:
                                result.systemchecktabactive_3,
                            remindstatus: result.remindstatus,
                            remindsentcomingdesc: result.remindsentcomingdesc,
                            remindsentdesc: result.remindsentdesc,
                            remindnotpaydelivery: result.remindnotpaydelivery,
                            customernotconfirmaddressalready:
                                result.customernotconfirmaddressalready,
                            customerconfirmaddressalready:
                                result.customerconfirmaddressalready,
                            comingyanimage: result.comingyanimage,
                            addresskey: result.addresskey,
                            queyan: result.queyan,
                            qty: result.qty,
                            increasesizedesc: result.increasesizedesc,
                            increasesizeorder: result.increasesizeorder,
                            ordertransaction: result.ordertransaction,
                            ordertransactionfrom: result.ordertransactionfrom,
                            queyandate: result.queyandate,
                            systemyandate: result.systemyandate,
                            yancomingdate: result.yancomingdate,
                            trackyan: result.trackyan,
                            systemtrackyan: result.systemtrackyan,
                            queyanimage: result.queyanimage,
                            systemyanimage: result.systemyanimage,
                            professortype: JSON.parse(
                                JSON.stringify(result.professortype)
                            ),
                            deliverytype: result.deliverytype,
                            customerproductstype: result.customerproductstype,
                            productprice: result.productprice,
                            originalprice: result.originalprice,
                            discount : result.discount,
                            productcode: result.productcode,
                            productsellorder: result.productsellorder,
                            customerorder: result.customerorder,
                            productkey: result.productkey,
                            customerproductssize: result.customerproductssize,
                            heartyanname: result.heartyanname,
                            productdetail: safelyParseJSON(
                                result.productdetail || []
                            ),
                            introductionyan: result.introductionyan,
                            phone: result.phone,
                            phoneemergency: result.phoneemergency,
                            productyanimage: result.productyanimage,
                            introduction: result.introduction,
                            email: result.email,
                            career: result.career,
                            introductioncareer: result.introductioncareer,
                            lineid: result.lineid,
                            facebook: result.facebook,
                            customerphonethai: result.customerphonethai,
                            productstatus: result.productstatus,
                            productstatusdesc: result.productstatusdesc,
                            customername: result.customername,
                            customerlastname: result.customerlastname,
                            customernickname: result.customernickname,
                            customerbirthdaytype: result.customerbirthdaytype,
                            customerage: result.customerage,
                            customergender: result.customergender,
                            customerbirthday: result.customerbirthday,
                            customercareer: result.customercareer,
                            customerlineid: result.customerlineid,
                            customerfacebook: result.customerfacebook,
                            addressdesc: result.addressdesc,
                            provincepostinter: result.provincepostinter,
                            citypostinter: result.citypostinter,
                            postcodepostinter: result.postcodepostinter,
                            customerphone: result.customerphone,
                            customeremail: result.customeremail,
                            DistrictName: result.districtname,
                            PostCode: result.postcode,
                            selectcountry: {
                                selectcountry: result.customerselectcountry,
                                selectcountrycode:
                                    result.customerselectcountrycode
                            },
                            ProvinceName: result.provincename,
                            BorderName: result.bordername,
                            CustomerSelectCountry: result.customerselectcountry,
                            CustomerSelectcountryCode:
                                result.customerselectcountrycode,
                            p_customerphoneemergency:
                                result.p_customerphoneemergency,
                            p_customerdesc: result.p_customerdesc,
                            customerphone: result.customerphone,
                            customerinternationalphone:
                                result.customerinternationalphone,
                            customeraddressType:
                                result.p_customeraddresstype ||
                                result.customeraddresstype,
                            children:
                                ProductChangeLog.length > 0
                                    ? ProductChangeLog
                                    : formState.listproductlogchange,
                            orderdate: result.orderdate
                        },
                        sellorderTransaction: result.sellorderTransaction,
                        orderdate: result.orderdate,
                        productjson: result.productjson,
                        children:
                            ProductChangeLog.length > 0
                                ? ProductChangeLog
                                : formState.listproductlogchange
                    };
                    console.log("product >> ", product);
                    console.log(
                        "index :" + index + "product" + JSON.stringify(product)
                    );

                    const update = Object.fromEntries(
                        Object.entries(product).filter(([, v]) => v != null)
                    );
                    console.log("updatelog" + index + 1 + " result " + update);

                    dataSource.value.push(product);
                    formState.productlist.listproduct = dataSource.value;
                    debugger
                    formState.listproduct = dataSource.value;

                    formState.listproductcreate = dataSource.value;
                    update.value++;
                });

               

                countlist = countlist + 1;
                setTimeout(() => {
                    totalBorrow.value = 0;
                    totalDelivery.value = 0;
                    totalLogoPrice.value = 0;
                    debugger
                    totalDiscount.value = 0;
                    funtotals();
                    funtotalsdelivery();
                    funtotalslogoprice();
                }, 1000);
            } catch (error) {
                console.log(error);
            }

            // if (is_edit_product.value.type == 'increasesize') {
            //     // if (e == undefined) {
            //     //     visible.value = false;
            //     // }
            //     setTimeout(() => {
            //         getCustomerProductlist();
            //     }, 2000);
            // }
        };

        const safelyParseJSON = obj => {
            if (typeof obj === "string") {
                try {
                    const parsedObject = JSON.parse(obj);
                    return parsedObject;
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    return null; // Return null or handle the error as needed
                }
            } else {
                return obj; // Return null or handle the error as needed
            }
        };
        const showprice = price => { 
            if (typeof price == undefined || price == undefined) return "-";
            return price?.toLocaleString() + " บาท";
        };
        const totalBorrow = ref(0);
        const totalDelivery = ref(0);
        const totalLogoPrice = ref(0);
        const totalDiscount = ref(0);
        const funtotals = () => {
            debugger
            dataSource.value.forEach(item => {
                try {
                    totalBorrow.value +=
                        item.userproduct.productprice *
                        (item.userproduct.qty || 1);

debugger
                    totalDiscount.value += parseInt(item.userproduct.discount || "0", 10);

                      

                } catch (error) {
                    console.log(error);
                }
            });
        };
        const funtotalslogoprice = () => {
            dataSource.value.forEach(item => {
                try {
                    if (item.userproduct?.logoprice) {
                        totalLogoPrice.value += parseInt(
                            item.userproduct?.logoprice
                        );
                    }  
                } catch (error) {
                    console.log(error);
                }
            });
        };
        const funtotalsdelivery = () => {
            dataSource.value.forEach(item => {
                try {
                    item.outside.forEach(element => {
                        if (element.price)
                            totalDelivery.value += parseInt(element.price);
                    });
                    item.inside.forEach(element => {
                        if (element.price)
                            totalDelivery.value += parseInt(element.price);
                    });
                    item.insidecod.forEach(element => {
                        if (element.price)
                            totalDelivery.value += parseInt(element.price);
                    });
                } catch (error) {
                    console.log(error);
                }
            });
        };
        const success = () => {
            Modal.success({
                title: "ลบสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const openNotification = massage => {
            notification["success"]({
                message: massage
            });
        };

        const showthaidate = date => {
            console.log("showthaidate ", date);
            if (date) {
                moment.locale("th");
                return moment(date)
                    .add(543, "year")
                    .format("LL");
            }
        };

        const handleCancel = e => {
            // update.value++
        };

        const onresultscan = e => {
            console.log(e);
            if (e.length) {
                isclick.value = "edit";
                isscan.value = true;
                is_edit_product.value = {
                    fact: false,
                    key: 0,
                    type: ""
                };
                openNotification("สแกนสำเร็จ");

                formStates.userproduct = { ...formStates.userproduct, ...e[0] };
                formStateStore.userproduct = {
                    ...formStates.userproduct,
                    ...e[0]
                };

                visible.value = true;
                update.value++;
            }
        };

        const getBase64FromUrl = async url => {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
            });
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("l");
        };
        const formatdatewithtype = element => {
            var d = "";
            if (element.TypeBirthday == "3") {
                d = moment(element.Birthday).format("DD/MM/YYYY");
            } else if (element.TypeBirthday == "2") {
                d = moment(element.Birthday)
                    .add(543, "year")
                    .format("DD/MM/YYYY");
            } else if (element.TypeBirthday == "4") {
                d = element.YearBirthday;
            }

            return d;
        };

        const showModalHistory = () => {
            gethistory();
            visiblehistory.value = true;
        };
        const handleOkHistory = e => {
            console.log(e);
            visiblehistory.value = false;
        };

        const showModalHistoryStock = () => {
            gethistorystock();
            visiblehistorystock.value = true;
        };
        const handleOkHistoryStock = e => {
            console.log(e);
            visiblehistorystock.value = false;
        };

        const gethistory = () => {
            BzbsUser.getproductlogchangehistory(
                props.formStates.user.sellordertransaction
            )
                .then(res => {
                    listhistorychangesize.value = res.data;
                    visiblehistory.value = true;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const gethistorystock = () => {
            BzbsUser.getproductstock(props.formStates.user.sellordertransaction)
                .then(res => {
                    listhistorystock.value = res.data;
                    visiblehistory.value = true;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onMoveToStock = () => {
            // movetostock
            // BzbsUser.getproductstock(props.formStates.user.sellordertransaction)
            //     .then(res => {
            //         listhistorystock.value = res.data;
            //         visiblehistory.value = true;
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        };
        const gotostockdetail = Stockuuid => {
            return BzbsUser.apiusestockdetail(Stockuuid)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var result = res.data[0][0];
                    window.location = `https://backofficestock.vercel.app/selllist??c=${result.CustomerOrder}&n=undefined&ot=${result.OrderTransaction}&so=${result.ProductSellOrder}#no-back-button`;
                    console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            gotostockdetail,
            listhistorychangesize,
            listhistorystock,
            gethistory,
            showModalHistory,
            handleOkHistory,
            showModalHistoryStock,
            handleOkHistoryStock,
            formatdate,
            getBase64FromUrl,
            isscan,
            onresultscan,
            handleCancel,
            showthaidate,
            openNotification,
            success,
            funtotalsdelivery,
            funtotalslogoprice,
            totalDelivery,
            totalLogoPrice,
            totalDiscount,
            totalBorrow,
            funtotals,
            addproduct,
            is_edit_product,
            handleclosemodalproduct,
            showprice,
            increasesize,
            resulttemp,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columns,
            columnshistory,
            columnstock,
            editingKey: "",
            editableData,
            edit,
            save,
            cancel,
            editIndex,
            list,
            productfrom,
            handleclosemodal,
            onDelete,
            update,
            onEdit,
            onEditStock,
            edit_productjson,
            formStateStore,
            isclick,
            computed,
            Storedata,
            visiblehistory,
            visiblehistorystock,
            onDeleteStock,
            onEditStockHistory,
            onReturnStock,
            formatdatewithtype,
            onMoveToStock,
            detail_sub_session_show
        };
    }
});
</script>
<style lang="scss">
.increasesize {
    .ant-modal-content {
        // background: linear-gradient(71deg, #f3faeb, #27c3f1, #7fe23a);
        // background-size: 180% 180%;
        // animation: gradient-animation 15s ease infinite;

        // @keyframes gradient-animation {
        //     0% {
        //         background-position: 0% 50%;
        //     }
        //     50% {
        //         background-position: 100% 50%;
        //     }
        //     100% {
        //         background-position: 0% 50%;
        //     }
        // }
        // background: radial-gradient(
        //     circle at 7.5% 24%,
        //     rgb(237, 161, 193) 0%,
        //     rgb(250, 178, 172) 25.5%,
        //     rgb(190, 228, 210) 62.3%,
        //     rgb(215, 248, 247) 93.8%
        // );
        // background: radial-gradient(
        //     328px at 2.9% 15%,
        //     rgb(191, 224, 251) 0%,
        //     rgb(232, 233, 251) 25.8%,
        //     rgb(252, 239, 250) 50.8%,
        //     rgb(234, 251, 251) 77.6%,
        //     rgb(240, 251, 244) 100.7%
        // );
        // background: radial-gradient(592px at 48.2% 50%, rgba(255, 255, 249, 0.6) 0%, rgb(160, 199, 254) 74.6%);
        // background: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
        background: linear-gradient(
            111.5deg,
            rgb(228, 247, 255) 21.9%,
            rgb(255, 216, 194) 92.2%
        );
    }
}
</style>
